import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FLivraisonInterface,
  MailConfirmationInterface,
  PCattarifInterface,
  UserInterface,
  UserPropInterface,
} from "../interfaces/UserInterface";
import { InitAppInterface } from "../interfaces/InitAppInterface";
import {
  COOKIE_TOKEN,
  STORAGE_CART,
  STORAGE_DISMISS_CONTENT,
  STORAGE_EDITING_FDOCENTETE,
  STORAGE_FARTICLES_COMPARE,
  STORAGE_FONT_SIZE,
  STORAGE_GLOBAL_SEARCH_BY_RAYON,
  STORAGE_ICONS,
  STORAGE_IMPERSONATE,
  STORAGE_INFINIT_SCROLL,
  STORAGE_INFINIT_SCROLL_ORDERS,
  STORAGE_NEW_BROWSER,
  STORAGE_PRICE_HT_COLOR,
  STORAGE_PRICE_TTC_COLOR,
  STORAGE_SHOW_ALL_FILTERS,
  STORAGE_SHOW_CONSTRUCTOR_REF,
  STORAGE_SHOW_HT,
  STORAGE_SHOW_KEYWORDS,
  STORAGE_SHOW_NOVE_REF,
  STORAGE_SHOW_STOCK_TERME,
  STORAGE_SHOW_TTC,
  STORAGE_STICKY_EDITOR,
  STORAGE_TOKEN,
  STORAGE_ZOOM,
} from "../utils/StorageUtils";
import {
  ConfigurationGroupInterface,
  ConfigurationInterface,
} from "../interfaces/ConfigurationInterface";
import { ROLE_ADMIN } from "../utils/UserUtils";
import { CategoryInterface } from "../interfaces/CategoryInterface";
import {
  CartInterface,
  ExpeditionInterface,
} from "../interfaces/CartInterface";
import {
  DriverInterface,
  FArticleInterface,
  FArticleSmallInterface,
  FDepotemplInterface,
  FGlossaireInterface,
} from "../interfaces/FArticleInterface";
import {
  GetPostOrdersInterface,
  ResponsePostOrderInterface,
} from "../interfaces/OrderInterface";
import {
  FFamilleInterface,
  FFamilleMappingInterface,
  UniverseInterface,
} from "../interfaces/ConfiguratorEnergyInterface";
import {
  NotificationInterface,
  UpdateNotificationInterface,
} from "../interfaces/NotificationInterface";
import {
  AccountingSituationInterface,
  FJournauxInterface,
} from "../interfaces/FEcritureRecInterface";
import {
  FilterFilterImageInterface,
  FilterFilterInterface,
} from "../interfaces/FilterInterface";
import {
  ManualCronInterface,
  TaskInterface,
} from "../interfaces/TaskInterface";
import { FPaysInterface } from "../interfaces/FPaysInterface";
import {
  ContactInterface,
  ContactMessageInterface,
  NewContactMessageInterface,
} from "../interfaces/ContactInterface";
import { DEFILANT_TEXTE, PAIEMENT_ALLOW } from "../utils/ConfigurationUtils";
import {
  MailInterface,
  MailTemplateInterface,
} from "../interfaces/MailInterface";
import { FiscalCodeInterface } from "../interfaces/FiscalCodeInterface";
import { NewsInterface } from "../interfaces/NewsInterface";
import { FavorisInterface } from "../interfaces/FavorisInterface";
import { TagInterface } from "../interfaces/TagInterface";
import { DismissContentInterface } from "../interfaces/ContentInterface";
import { FilterFDocenteteInterface } from "../interfaces/FDocenteteInterface";

export interface GlobalState {
  init?: boolean;
  user?: UserInterface;
  isAdmin?: boolean;
  catTarif?: number;
  cart?: CartInterface;
  cartUpsellings?: FArticleSmallInterface[];
  token?: string;
  refreshPage?: boolean;
  toggled?: boolean;
  configurations?: ConfigurationInterface[];
  categories?: CategoryInterface[];
  drivers?: DriverInterface[];
  fGlossaires?: FGlossaireInterface[];
  expeditions?: ExpeditionInterface[];
  openCart?: boolean;
  nbOrderNeedPayAcompte?: number;
  zoom?: string;
  nbAdministrationNotYetTransform?: number;
  nbPl?: number;
  universes?: UniverseInterface[];
  fFamilleMappings?: FFamilleMappingInterface[];
  fFamilles?: FFamilleInterface[];
  notifications?: NotificationInterface[];
  nbNewNotifications?: number;
  mailConfirmation?: MailConfirmationInterface;
  fJournaux?: FJournauxInterface[];
  accountingSituation?: AccountingSituationInterface;
  filterImages?: FilterFilterImageInterface[];
  hasAcceptCookie?: boolean;
  nbFilterArticle?: number;
  nbFilterArticle2?: number;
  customFilters?: FilterFilterInterface[];
  impersonate?: string;
  crons?: TaskInterface[];
  tokenMercure?: string;
  topics?: string[];
  configurationGroups?: ConfigurationGroupInterface[];
  fPays?: FPaysInterface[];
  newContactMessage?: NewContactMessageInterface;
  newMessageAdmin?: number;
  newMessageUser?: number;
  contactMessageIdToDelete?: number;
  updateContactMessage?: ContactMessageInterface;
  updateContact?: ContactInterface;
  paymentAllow?: boolean;
  newBrowser?: boolean;
  mailActivatedTrigger?: boolean;
  showIcons?: boolean;
  infinitScroll?: boolean;
  nbWaitingSav?: number;
  nbSavServiceTodo?: number;
  nbWaitingSavUser?: number;
  mails?: MailInterface[];
  mailTemplates?: MailTemplateInterface[];
  nbErrorPayments?: number;
  fontSize?: number;
  showKeywords?: boolean;
  showNoveRef?: boolean;
  showConstructorRef?: boolean;
  showStockTerme?: boolean;
  showTTC?: boolean;
  showHT?: boolean;
  showAllFilters?: boolean;
  priceTTCColor?: string;
  priceHTColor?: string;
  showFloComponent?: boolean;
  headerText?: string;
  editingFDocentete?: string | null;
  fiscalCodes?: FiscalCodeInterface[];
  postOrders?: GetPostOrdersInterface[];
  stickyEditor?: boolean;
  news?: NewsInterface[];
  userProp?: UserPropInterface;
  loginCookieIcecat?: string[];
  favoris?: FavorisInterface[];
  addedFavoris?: boolean;
  tags?: TagInterface[];
  dismissContent?: DismissContentInterface[];
  infinitScrollOrders?: boolean;
  fArticlesToCompare?: FArticleInterface[];
  addedCompare?: boolean;
  globalSearchByRayon?: boolean;
  fDepotempls?: FDepotemplInterface[];
  fournisseurs?: UserInterface[];
  pCattarifs?: PCattarifInterface[];
  cacheName?: string;
  newEdi?: number;
  filterFDocentetes?: FilterFDocenteteInterface[];
}

const initialState: GlobalState = {
  init: false,
  user: undefined,
  zoom: "100",
  isAdmin: false,
  catTarif: 4,
  cart: undefined,
  cartUpsellings: undefined,
  token: undefined,
  refreshPage: true,
  toggled: false,
  configurations: undefined,
  categories: undefined,
  drivers: undefined,
  fGlossaires: undefined,
  expeditions: undefined,
  openCart: false,
  nbOrderNeedPayAcompte: undefined,
  nbAdministrationNotYetTransform: undefined,
  nbPl: undefined,
  universes: undefined,
  fFamilleMappings: undefined,
  fFamilles: undefined,
  notifications: undefined,
  nbNewNotifications: undefined,
  mailConfirmation: undefined,
  fJournaux: undefined,
  accountingSituation: undefined,
  filterImages: undefined,
  hasAcceptCookie: false,
  nbFilterArticle: undefined,
  nbFilterArticle2: undefined,
  customFilters: undefined,
  impersonate: undefined,
  crons: undefined,
  tokenMercure: undefined,
  topics: undefined,
  configurationGroups: undefined,
  fPays: undefined,
  newContactMessage: undefined,
  newMessageAdmin: undefined,
  newMessageUser: undefined,
  contactMessageIdToDelete: undefined,
  updateContactMessage: undefined,
  updateContact: undefined,
  paymentAllow: undefined,
  newBrowser: undefined,
  mailActivatedTrigger: false,
  showIcons: undefined,
  infinitScroll: undefined,
  nbWaitingSav: undefined,
  nbSavServiceTodo: undefined,
  nbWaitingSavUser: undefined,
  mails: undefined,
  mailTemplates: undefined,
  nbErrorPayments: undefined,
  fontSize: undefined,
  showKeywords: undefined,
  showNoveRef: undefined,
  showConstructorRef: undefined,
  showStockTerme: undefined,
  showTTC: undefined,
  showHT: undefined,
  showAllFilters: undefined,
  priceTTCColor: undefined,
  priceHTColor: undefined,
  showFloComponent: false,
  headerText: undefined,
  editingFDocentete: undefined,
  postOrders: undefined,
  stickyEditor: undefined,
  news: undefined,
  userProp: undefined,
  loginCookieIcecat: undefined,
  favoris: undefined,
  addedFavoris: false,
  tags: undefined,
  dismissContent: undefined,
  infinitScrollOrders: undefined,
  fArticlesToCompare: [],
  addedCompare: false,
  globalSearchByRayon: false,
  fDepotempls: undefined,
  fournisseurs: undefined,
  pCattarifs: undefined,
  cacheName: undefined,
  newEdi: undefined,
  filterFDocentetes: undefined,
};

const _saveUserStorage = (
  state: GlobalState,
  user: UserInterface | undefined
) => {
  if (user === undefined) {
    return;
  }
  if (user.token) {
    localStorage.setItem(STORAGE_TOKEN, user.token);
    state.token = user.token;
  }
};

const _setUser = (state: GlobalState, user: UserInterface | undefined) => {
  _saveUserStorage(state, user);
  state.user = user;
  state.isAdmin = user?.roles.includes(ROLE_ADMIN) ?? false;
};

const _setCart = (state: GlobalState, cart: CartInterface) => {
  localStorage.setItem(STORAGE_CART, cart.id);
  state.cart = cart;
};

const _resetApp = (state: GlobalState) => {
  localStorage.removeItem(STORAGE_CART);
  localStorage.removeItem(STORAGE_TOKEN);
  for (const [key] of Object.entries(initialState)) {
    if (["refreshPage", "fArticlesToCompare"].includes(key)) {
      continue;
    }
    if (!["mails", "mailTemplates"].includes(key)) {
      // @ts-ignore
      state[key] = initialState[key];
    }
  }
};

export const globalSlice = createSlice({
  name: "appReducers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set: (state, action: PayloadAction<GlobalState>) => {
      for (const [key] of Object.entries(action.payload)) {
        // @ts-ignore
        state[key] = action.payload[key];
      }
    },
    resetApp: (state) => {
      _resetApp(state);
    },
    setCart: (state, action: PayloadAction<CartInterface>) => {
      _setCart(state, action.payload);
    },
    addOrder: (state, action: PayloadAction<ResponsePostOrderInterface>) => {
      if (action.payload.order.user !== null) {
        if (action.payload.order.token !== null) {
          action.payload.order.user.token = action.payload.order.token;
        }
        _setUser(state, action.payload.order.user);
      }
      if (action.payload.order.cart) {
        _setCart(state, action.payload.order.cart);
      }
      state.nbPl = action.payload.nbPl;
      state.nbOrderNeedPayAcompte = action.payload.nbOrderNeedPayAcompte;
    },
    setUser: (state, action: PayloadAction<UserInterface>) => {
      _setUser(state, action.payload);
    },
    initApp: (state, action: PayloadAction<InitAppInterface>) => {
      _resetApp(state);
      state.init = true;
      if (state.cacheName === undefined) {
        state.cacheName = Math.round(Date.now() / 100).toString();
      }
      _setUser(state, action.payload.user);
      _setCart(state, action.payload.cart);
      state.accountingSituation = action.payload.accountingSituation;
      state.filterFDocentetes = action.payload.filterFDocentetes;
      state.newBrowser = localStorage.getItem(STORAGE_NEW_BROWSER) === "true";
      state.stickyEditor =
        localStorage.getItem(STORAGE_STICKY_EDITOR) === "true";
      state.configurations = action.payload.configurations;
      state.paymentAllow =
        action.payload.configurations.find(
          (x) => x.identifier === PAIEMENT_ALLOW
        )?.value === "1";
      state.hasAcceptCookie = localStorage.getItem(COOKIE_TOKEN) === "true";
      state.drivers = action.payload.drivers;
      state.categories = action.payload.categories;
      state.catTarif = action.payload.catTarif;
      state.fGlossaires = action.payload.fGlossaires;
      state.expeditions = action.payload.expeditions;
      state.nbOrderNeedPayAcompte = action.payload.nbOrderNeedPayAcompte;
      state.nbAdministrationNotYetTransform =
        action.payload.nbAdministrationNotYetTransform;
      state.nbPl = action.payload.nbPl;
      state.universes = action.payload.universes;
      state.fFamilleMappings = action.payload.fFamilleMappings;
      state.fFamilles = action.payload.fFamilles;
      state.notifications = action.payload.notifications;
      state.nbNewNotifications = action.payload.nbNewNotifications;
      state.mailConfirmation = action.payload.mailConfirmation;
      state.fJournaux = action.payload.fJournaux;
      state.nbFilterArticle = action.payload.nbFilterArticle;
      state.nbFilterArticle2 = action.payload.nbFilterArticle2;
      state.customFilters = action.payload.customFilters;
      state.crons = action.payload.crons;
      state.tokenMercure = action.payload.tokenMercure;
      state.topics = action.payload.topics;
      state.configurationGroups = action.payload.configurationGroups;
      state.fPays = action.payload.fPays;
      state.newMessageAdmin = action.payload.newMessageAdmin;
      state.newMessageUser = action.payload.newMessageUser;
      state.nbWaitingSav = action.payload.nbWaitingSav;
      state.fDepotempls = action.payload?.fDepotempls;
      state.fournisseurs = action.payload?.fournisseurs;
      state.pCattarifs = action.payload?.pCattarifs;
      state.nbSavServiceTodo = action.payload.nbSavServiceTodo;
      state.nbWaitingSavUser = action.payload.nbWaitingSavUser;
      state.newEdi = action.payload.newEdi;
      state.showIcons = localStorage.getItem(STORAGE_ICONS) === "true";
      state.infinitScroll =
        localStorage.getItem(STORAGE_INFINIT_SCROLL) !== "false";
      state.infinitScrollOrders =
        localStorage.getItem(STORAGE_INFINIT_SCROLL_ORDERS) !== "false";
      state.nbErrorPayments = action.payload.nbErrorPayments;

      const newFontSize = localStorage.getItem(STORAGE_FONT_SIZE);
      state.fontSize = newFontSize ? Number(newFontSize) ?? 10 : 10;
      state.showKeywords =
        localStorage.getItem(STORAGE_SHOW_KEYWORDS) !== "false";
      state.showNoveRef =
        localStorage.getItem(STORAGE_SHOW_NOVE_REF) !== "false";
      state.showConstructorRef =
        localStorage.getItem(STORAGE_SHOW_CONSTRUCTOR_REF) !== "false";
      state.showStockTerme =
        localStorage.getItem(STORAGE_SHOW_STOCK_TERME) === "true";
      state.showTTC = localStorage.getItem(STORAGE_SHOW_TTC) !== "false";
      state.showHT = localStorage.getItem(STORAGE_SHOW_HT) !== "false";
      state.showAllFilters =
        localStorage.getItem(STORAGE_SHOW_ALL_FILTERS) === "true";
      state.priceTTCColor =
        localStorage.getItem(STORAGE_PRICE_TTC_COLOR) ?? "grey";
      state.priceHTColor =
        localStorage.getItem(STORAGE_PRICE_HT_COLOR) ?? "purple";
      const fArticlesToCompareJson = localStorage.getItem(
        STORAGE_FARTICLES_COMPARE
      );
      if (fArticlesToCompareJson) {
        state.fArticlesToCompare = JSON.parse(fArticlesToCompareJson);
      }

      state.headerText = action.payload.configurations.find(
        (x) => x.identifier === DEFILANT_TEXTE
      )?.value;
      state.editingFDocentete =
        localStorage.getItem(STORAGE_EDITING_FDOCENTETE) ?? null;
      if (action.payload.fiscalCodes) {
        action.payload.fiscalCodes.sort((a, b) => a.code.localeCompare(b.code));
      }
      state.fiscalCodes = action.payload.fiscalCodes ?? [];
      state.postOrders = action.payload.postOrders;
      state.impersonate =
        localStorage.getItem(STORAGE_IMPERSONATE) ?? undefined;
      state.news = action.payload.news;
      state.userProp = action.payload.userProp;
      state.favoris = action.payload.favoris;
      state.tags = action.payload.tags;
      state.zoom = localStorage.getItem(STORAGE_ZOOM) ?? "100";
      state.globalSearchByRayon =
        localStorage.getItem(STORAGE_GLOBAL_SEARCH_BY_RAYON) === "true";
      let dismissContent: string | undefined | null = localStorage.getItem(
        STORAGE_DISMISS_CONTENT
      );
      if (dismissContent) {
        try {
          state.dismissContent = JSON.parse(dismissContent);
        } catch (e) {
          state.dismissContent = [];
        }
      } else {
        state.dismissContent = [];
      }
    },
    updateNotification: (
      state,
      action: PayloadAction<UpdateNotificationInterface>
    ) => {
      state.nbNewNotifications = action.payload.nbNewNotifications;
      if (state.notifications !== undefined) {
        let index = state.notifications.findIndex(
          (notification) => notification.id === action.payload.notification.id
        );
        if (index >= 0) {
          state.notifications[index] = action.payload.notification;
        } else {
          state.notifications.push(action.payload.notification);
        }
      }
    },
    addUpdateLivraison: (state, action: PayloadAction<FLivraisonInterface>) => {
      if (!state.user?.fLivraisons) {
        return;
      }
      const index = state.user?.fLivraisons.findIndex(
        (x) => x.liNo === action.payload.liNo
      );
      if (index >= 0) {
        state.user.fLivraisons[index] = action.payload;
      } else {
        state.user.fLivraisons.push(action.payload);
      }
      if (action.payload.liPrincipal === 1) {
        for (const livraison of state.user.fLivraisons) {
          if (livraison.liNo !== action.payload.liNo) {
            livraison.liPrincipal = 0;
          }
        }
      }
    },
    deleteExpedition: (state, action: PayloadAction<number>) => {
      state.expeditions = state.expeditions?.filter(
        (x) => x.id !== action.payload
      );
    },
    addUpdateExpedition: (
      state,
      action: PayloadAction<ExpeditionInterface>
    ) => {
      const expeditionIndex = state.expeditions?.findIndex(
        (x) => x.id === action.payload.id
      );
      if (
        expeditionIndex !== undefined &&
        expeditionIndex >= 0 &&
        state.expeditions
      ) {
        state.expeditions[expeditionIndex] = action.payload;
      } else {
        state.expeditions?.push(action.payload);
      }
    },
    removeFilterImage: (state, action: PayloadAction<number>) => {
      if (!state.filterImages) {
        return;
      }
      state.filterImages = state.filterImages.filter(
        (x) => x.id !== action.payload
      );
    },
    updateCron: (state, action: PayloadAction<ManualCronInterface>) => {
      if (!state.crons) {
        return;
      }
      const taskCron = state.crons.find((x) => x.name === action.payload.name);
      if (taskCron) {
        taskCron.previous = action.payload;
        state.crons = [...state.crons];
      }
    },
    updateConfiguration: (
      state,
      action: PayloadAction<ConfigurationInterface>
    ) => {
      if (state.configurations) {
        const index = state.configurations.findIndex(
          (x) => x.id === action.payload.id
        );
        if (index !== -1) {
          state.configurations[index] = action.payload;
        }
      }
      if (state.configurationGroups) {
        for (const configurationGroup of state.configurationGroups) {
          const index = configurationGroup.configurations.findIndex(
            (x) => x.id === action.payload.id
          );
          if (index !== -1) {
            configurationGroup.configurations[index] = action.payload;
            break;
          }
        }
      }
    },
    updateDriver: (state, action: PayloadAction<DriverInterface>) => {
      if (!state.drivers) {
        return;
      }
      const index = state.drivers.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.drivers[index] = action.payload;
      } else {
        state.drivers.push(action.payload);
      }
    },
    updateFilterImage: (
      state,
      action: PayloadAction<FilterFilterImageInterface>
    ) => {
      if (!state.filterImages) {
        return;
      }
      const index = state.filterImages.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index >= 0) {
        state.filterImages[index] = action.payload;
      }
    },
    addNotification: (state, action: PayloadAction<NotificationInterface>) => {
      if (!state.notifications) {
        state.notifications = [action.payload];
      } else {
        state.notifications.unshift(action.payload);
      }
      if (state.nbNewNotifications) {
        state.nbNewNotifications += 1;
      } else {
        state.nbNewNotifications = 1;
      }
    },
    triggerMailValidated: (state) => {
      state.mailConfirmation = undefined;
      state.mailActivatedTrigger = !state.mailActivatedTrigger;
    },
  },
});

export const {
  set,
  resetApp,
  initApp,
  setCart,
  addOrder,
  updateNotification,
  setUser,
  deleteExpedition,
  addUpdateExpedition,
  removeFilterImage,
  addUpdateLivraison,
  updateCron,
  updateConfiguration,
  updateDriver,
  addNotification,
  updateFilterImage,
  triggerMailValidated,
} = globalSlice.actions;

export default globalSlice.reducer;
