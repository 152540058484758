import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import getErrorApi from "../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { isDesktop } from "react-device-detect";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const RawHtmlScreen: React.FC = React.memo(() => {
  const { state }: any = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [downloaded, setDownloaded] = React.useState(false);

  const load = React.useCallback(async () => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject && searchParamsObject.url) {
      const response = await requestApi({
        method: GET,
        path: searchParamsObject.url,
        allowError: false,
        getRawResponse: /\/api\/f_docentetes\/.*\/html/.test(
          searchParamsObject.url
        ),
        token: token,
      });
      if (response.statusCode === 200) {
        if (response?.content?.headers) {
          for (const header of response.content.headers) {
            if (
              header[0] === "content-type" &&
              header[1] === "application/json"
            ) {
              response.content = await response.content.json();
              break;
            }
          }
        }
        if (response.content?.html) {
          document.getElementsByTagName("html")[0].innerHTML =
            response.content.html;
          const url = new URL("http://" + searchParamsObject.url);
          const params = Object.fromEntries(new URLSearchParams(url.search));
          if (params.print === "1") {
            window.print();
          }
        } else {
          const base64 = response.content.base64;
          let blob;
          if (base64) {
            const binStr = atob(base64);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            blob = new Blob([arr], { type: "application/pdf" });
          } else {
            blob = new Blob([await response.content.blob()], {
              type: "application/pdf",
            });
          }
          const url = window.URL.createObjectURL(blob);
          if (!isDesktop) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = (searchParamsObject.filename ?? "download") + ".pdf";
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloaded(true);
          } else {
            window.open(url, "_self");
          }
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    } else {
      document.getElementsByTagName("html")[0].innerHTML = state?.html;
    }
  }, [searchParams, state?.html, t, token]);

  React.useEffect(() => {
    document.title = getTitle("rawHtml");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    load();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {downloaded ? (
        <Container
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography>Fichier téléchargé</Typography>
          <DoneIcon sx={{ fontSize: "10em" }} color="success" />
        </Container>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
});

export default RawHtmlScreen;
