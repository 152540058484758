const Sage19Validator = (sageName: string | null, canBeEmpty = true, canHaveSpace = true) => {
  sageName = sageName?.trim() ?? "";
  if (!canBeEmpty && sageName.length === 0) {
    return "error.not_empty";
  }
  if (sageName.length > 19) {
    return "error.sageName.maxLength19";
  }
  if (!canHaveSpace && sageName.includes(" ")) {
    return "error.sageName.noSpace";
  }
  return "";
};
export default Sage19Validator;
