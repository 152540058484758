import React, { forwardRef, useImperativeHandle } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { TextField, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { InputInterface } from "../../../interfaces/InputInterface";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import {
  CAN_SEARCH_SAGE_DO_TYPE,
  FDOCENTETE_FILTER_TYPE_CHECKBOX,
  FDOCENTETE_FILTER_TYPE_TEXT,
} from "../../../utils/DoceneteteUtils";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { toastr } from "react-redux-toastr";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH, POST } from "../../../utils/MethodUtils";
import { FILTER_FDOCENTETE_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { set } from "../../../app/globalSlice";
import {
  FilterFDocenteteInterface,
  FilterFDocenteteOptionInterface,
} from "../../../interfaces/FDocenteteInterface";

const clone = require("clone");

interface State2 {
  type: string;
  setOptions: Function;
  index: number;
  filterFDocentete?: FilterFDocenteteInterface;
}

interface State3 {
  doType: number;
  setAllDoTypeChecked: Function;
  checkAllIfNotSelection: Function;
  filterFDocentete?: FilterFDocenteteInterface;
}

interface State4 {
  setCreating: Function;
  filterFDocentete?: FilterFDocenteteInterface;
}

interface DoTypeRefInterface {
  doType: number;
  ref: any;
}

interface FormState {
  name: InputInterface;
  type: InputInterface;
}

interface FormState2 {
  name: InputInterface;
}

interface OptionFormInterface {
  ref: any;
}

const AddFilterOptionFDocenteteComponent = React.memo(
  forwardRef(({ type, setOptions, index, filterFDocentete }: State2, ref) => {
    let oldFilterFDocenteteOption: FilterFDocenteteOptionInterface | undefined =
      undefined;
    if (
      filterFDocentete?.filterFDocenteteOptions &&
      filterFDocentete?.filterFDocenteteOptions.length > index
    ) {
      oldFilterFDocenteteOption =
        filterFDocentete.filterFDocenteteOptions[index];
    }
    const getDefaultValues = React.useCallback((): FormState2 => {
      return {
        name: { value: oldFilterFDocenteteOption?.name ?? "", error: "" },
      };
    }, [oldFilterFDocenteteOption?.name]);
    const { t } = useTranslation();
    const [values, setValues] = React.useState<FormState2>(getDefaultValues());
    const handleChange = React.useCallback(
      (prop: keyof FormState2) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const nameError = notEmptyValidator(values.name.value);
      if (nameError) {
        const newValue: FormState2 = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        name: values.name.value,
        ...(oldFilterFDocenteteOption && {
          oldId: oldFilterFDocenteteOption.id,
        }),
      };
    }, [oldFilterFDocenteteOption, values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
        <TextField
          fullWidth={true}
          autoComplete="off"
          error={!!values.name.error}
          helperText={t(values.name.error ?? "")}
          sx={{
            width: "100%",
          }}
          required
          type="text"
          value={values.name.value}
          onChange={handleChange("name")}
          label={t("field.nameOption")}
        />
        {index > 0 && (
          <IconButton
            onClick={() => {
              setOptions((v: (OptionFormInterface | undefined)[]) => {
                v[index] = undefined;
                return [...v];
              });
            }}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </Box>
    );
  })
);

const FilterDoTypeFDocenteteComponent = React.memo(
  forwardRef(
    (
      {
        doType,
        setAllDoTypeChecked,
        checkAllIfNotSelection,
        filterFDocentete,
      }: State3,
      ref
    ) => {
      const getChecked = React.useCallback(() => {
        if (filterFDocentete?.doTypes && filterFDocentete.doTypes.length > 0) {
          return filterFDocentete.doTypes.includes(doType);
        }
        return false;
      }, [doType, filterFDocentete?.doTypes]);
      const [checked, setChecked] = React.useState<boolean>(getChecked());
      const { t } = useTranslation();

      const getValue = React.useCallback(() => {
        return checked;
      }, [checked]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        setChecked(newChecked: boolean) {
          return setChecked(newChecked);
        },
      }));

      React.useEffect(() => {
        if (checked) {
          setAllDoTypeChecked(false);
        }
        checkAllIfNotSelection();
      }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setChecked(getChecked());
      }, [filterFDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event, newChecked) => {
                  setChecked(newChecked);
                }}
              />
            }
            label={t("word.fdocentete.doType." + doType)}
          />
        </FormGroup>
      );
    }
  )
);

export const FormFilterFDocenteteComponent = React.memo(
  forwardRef(({ setCreating, filterFDocentete }: State4, ref) => {
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const filterFDocentetes = useAppSelector(
      (state: RootState) => state.globalState.filterFDocentetes
    );
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: { value: filterFDocentete?.name ?? "", error: "" },
        type: {
          value: filterFDocentete?.type ?? FDOCENTETE_FILTER_TYPE_CHECKBOX,
          error: "",
        },
      };
    }, [filterFDocentete]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [loading, setLoading] = React.useState(false);
    const [allDoTypeChecked, setAllDoTypeChecked] = React.useState<boolean>(
      filterFDocentete?.doTypes === undefined ||
        filterFDocentete.doTypes === null
    );
    const getOptions = React.useCallback((): OptionFormInterface[] => {
      if (
        filterFDocentete?.filterFDocenteteOptions &&
        filterFDocentete.filterFDocenteteOptions.length > 0
      ) {
        return filterFDocentete.filterFDocenteteOptions.map(() => {
          return {
            ref: React.createRef(),
          };
        });
      }
      return [
        {
          ref: React.createRef(),
        },
      ];
    }, [filterFDocentete?.filterFDocenteteOptions]);
    const [doTypeRefs] = React.useState((): DoTypeRefInterface[] => {
      return CAN_SEARCH_SAGE_DO_TYPE.map((doType) => {
        return {
          doType: doType,
          ref: React.createRef(),
        };
      });
    });
    const dispatch = useAppDispatch();
    const [options, setOptions] = React.useState<
      (OptionFormInterface | undefined)[]
    >(getOptions());
    const theme = useTheme();
    const { t } = useTranslation();
    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );
    const onClick = React.useCallback(
      (prop: keyof FormState) => (event: React.MouseEvent<HTMLElement>) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: (event.target as HTMLInputElement).value,
              error: "",
            },
          };
        });
      },
      []
    );
    const createFilterFDocentete = React.useCallback(async () => {
      setLoading(true);
      const nameError = notEmptyValidator(values.name.value);
      const optionValues = options
        .filter((o) => o)
        .map((o) => o!.ref.current.getValue());
      if (nameError || optionValues.includes(undefined)) {
        const newValue: FormState = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      let doTypes = null;
      if (!allDoTypeChecked) {
        doTypes = doTypeRefs
          .map((d) => {
            if (d.ref.current.getValue()) {
              return d.doType;
            }
            return null;
          })
          .filter((d) => d !== null);
      }
      let method = POST;
      let path = FILTER_FDOCENTETE_URL;
      if (filterFDocentete) {
        method = PATCH;
        path += "/" + filterFDocentete.id;
      }
      const response = await requestApi({
        method: method,
        path: path,
        allowError: true,
        token: token,
        body: {
          name: values.name.value,
          type: values.type.value,
          filterFDocenteteOptions: optionValues,
          doTypes: doTypes,
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        toastr.success(t("word.success"), "");
        const newFilterFDocentetes: FilterFDocenteteInterface[] =
          clone(filterFDocentetes);
        if (response.statusCode === 201) {
          newFilterFDocentetes.push(response.content);
          dispatch(set({ filterFDocentetes: newFilterFDocentetes }));
        } else {
          const index = newFilterFDocentetes.findIndex(
            (f) => f.id === filterFDocentete?.id
          );
          if (index >= 0) {
            newFilterFDocentetes[index] = response.content;
          }
          dispatch(set({ filterFDocentetes: [...newFilterFDocentetes] }));
        }
        setCreating(false);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [
      allDoTypeChecked,
      dispatch,
      doTypeRefs,
      filterFDocentete,
      filterFDocentetes,
      options,
      setCreating,
      t,
      token,
      values,
    ]);

    const checkAllIfNotSelection = React.useCallback(() => {
      const noneChecked = doTypeRefs
        .map((doTypeRef) => !doTypeRef.ref.current.getValue())
        .reduce((partialSum, a) => partialSum && a, true);
      const allChecked = doTypeRefs
        .map((doTypeRef) => doTypeRef.ref.current.getValue())
        .reduce((partialSum, a) => partialSum && a, true);

      if (noneChecked || allChecked) {
        setAllDoTypeChecked(true);
      }
    }, [doTypeRefs]);

    React.useEffect(() => {
      if (allDoTypeChecked) {
        for (const doTypeRef of doTypeRefs) {
          doTypeRef.ref.current?.setChecked(false);
        }
      }
    }, [allDoTypeChecked]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Accordion expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              justifyContent: "flex-start",
            }}
          >
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.name.error}
              helperText={t(values.name.error ?? "")}
              sx={{
                width: "100%",
                marginY: 1,
              }}
              required
              type="text"
              value={values.name.value}
              onChange={handleChange("name")}
              label={t("field.nameFilter")}
            />
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup row value={values.type.value}>
                <FormControlLabel
                  disabled={!!filterFDocentete?.id}
                  value={FDOCENTETE_FILTER_TYPE_CHECKBOX}
                  control={
                    <Radio onClick={onClick("type")} sx={{ paddingY: 0 }} />
                  }
                  label={t("word.checkbox")}
                />
                <FormControlLabel
                  disabled={!!filterFDocentete?.id}
                  value={FDOCENTETE_FILTER_TYPE_TEXT}
                  control={
                    <Radio onClick={onClick("type")} sx={{ paddingY: 0 }} />
                  }
                  label={t("word.text")}
                />
              </RadioGroup>
            </FormControl>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allDoTypeChecked}
                    onChange={(event, newChecked) => {
                      setAllDoTypeChecked(newChecked);
                    }}
                  />
                }
                label={t("word.all")}
              />
            </FormGroup>
            {doTypeRefs.map((doTypeRef, indexDoTypeRef) => (
              <FilterDoTypeFDocenteteComponent
                key={indexDoTypeRef}
                filterFDocentete={filterFDocentete}
                doType={doTypeRef.doType}
                ref={doTypeRef.ref}
                setAllDoTypeChecked={setAllDoTypeChecked}
                checkAllIfNotSelection={checkAllIfNotSelection}
              />
            ))}
            <Divider />
            {options.map((option, indexOption) => (
              <React.Fragment key={indexOption}>
                {option && (
                  <AddFilterOptionFDocenteteComponent
                    filterFDocentete={filterFDocentete}
                    type={values.type.value}
                    ref={option.ref}
                    index={indexOption}
                    setOptions={setOptions}
                  />
                )}
              </React.Fragment>
            ))}
            <Box sx={{ textAlign: "center" }}>
              <IconButton
                onClick={() => {
                  setOptions((v) => {
                    return [...v, { ref: React.createRef() }];
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <LoadingButton
            disabled={loading}
            onClick={() => {
              setCreating(false);
            }}
          >
            {t("word.cancel")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={createFilterFDocentete}
          >
            {t("word.validate")}
          </LoadingButton>
        </Box>
      </>
    );
  })
);

const AddFilterFDocenteteComponent = React.memo(
  forwardRef((props, ref) => {
    const [creating, setCreating] = React.useState<boolean>(false);
    const { t } = useTranslation();

    return (
      <>
        {creating ? (
          <FormFilterFDocenteteComponent setCreating={setCreating} />
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title={t("word.addFilter")}>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  setCreating(true);
                }}
              >
                <AddIcon />
              </LoadingButton>
            </Tooltip>
          </Box>
        )}
      </>
    );
  })
);

export default AddFilterFDocenteteComponent;
