import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { F_COMPTET_USER_URL } from "../../../utils/UrlsUtils";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { FComptetUser } from "../../../interfaces/UserInterface";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { ADMINISTRATION } from "../../../utils/RegisterUtils";
import { InputInterface } from "../../../interfaces/InputInterface";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ImpersonateButtonComponent from "./ImpersonateButtonComponent";
import { LoadingButton } from "@mui/lab";
import TransformIcon from "@mui/icons-material/Transform";
import UserFormComponent from "../filter/admin/form/userForm/UserFormComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DrawerComponent from "../DrawerComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";

interface FormState {
  search: InputInterface;
  status: InputInterface;
}

const minWidth = 900;

const UserAdminComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const nbAdministrationNotYetTransform = useAppSelector(
    (state: RootState) => state.globalState.nbAdministrationNotYetTransform
  );
  const width = window.innerWidth;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState("50");
  const [users, setUsers] = React.useState<FComptetUser[] | undefined>(
    undefined
  );
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const handleDrawerClose = React.useCallback(() => {
    setFComptetUser(undefined);
  }, []);

  const handleDrawerOpen = React.useCallback(
    (thisFComptetUser: FComptetUser | undefined) => {
      setFComptetUser(thisFComptetUser);
    },
    []
  );
  const getColumns = React.useCallback(() => {
    const result: GridColDef[] = [
      {
        field: "userIdentifier",
        flex: 0,
        headerName: t("column.id"),
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.userIdentifier ?? ""}
          />
        ),
      },
      {
        field: "ctIntitule",
        flex: 1,
        headerName: t("column.name"),
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "ctEmail",
        flex: 1,
        headerName: t("word.email"),
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.ctEmail ?? ""}
          />
        ),
      },
      {
        field: "admin",
        flex: 0,
        headerName: t("word.admin"),
        headerClassName: "background-nove",
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.admin ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </>
        ),
      },
      {
        field: "ctControlenc",
        flex: 0,
        headerName: t("word.status"),
        headerClassName: "background-nove",
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams) => (
          // 0 = Contrôle automatique
          // 1 = Selon code risque
          // 2 = Compte bloqué
          <>
            {params.row.type === ADMINISTRATION ? (
              <Tooltip
                title={t("sentence.administrationNotYetTransformAction")}
              >
                <IconButton>
                  <TransformIcon color="warning" />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                {params.row.ctControlenc === 0 && (
                  <Tooltip title={t("word.ctControlenc.0")}>
                    <IconButton>
                      <HowToRegIcon color="success" />
                    </IconButton>
                  </Tooltip>
                )}
                {params.row.ctControlenc === 1 && (
                  <Tooltip title={t("word.ctControlenc.1")}>
                    <IconButton>
                      <HowToRegIcon color="info" />
                    </IconButton>
                  </Tooltip>
                )}
                {params.row.ctControlenc === 2 && (
                  <Tooltip title={t("word.ctControlenc.2")}>
                    <IconButton>
                      <CloseIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
        ),
      },
    ];
    result.push({
      field: "actions",
      headerName: t("column.actions"),
      headerClassName: "background-nove",
      filterable: false,
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <ImpersonateButtonComponent
            userIdentifier={params.row.userIdentifier}
          />
          <Tooltip title={t("word.seeUserInfo")}>
            <LoadingButton
              variant="text"
              color="inherit"
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                padding: "12px",
              }}
              onClick={() => handleDrawerOpen(params.row)}
            >
              <VisibilityIcon />
            </LoadingButton>
          </Tooltip>
        </>
      ),
    });
    return result;
  }, [handleDrawerOpen, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const [fComptetUser, setFComptetUser] = React.useState<
    FComptetUser | undefined
  >(undefined);
  const [search, setSearch] = React.useState<string>(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.search ?? "";
  });
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (users !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("search")) {
        searchParamsObject.search = "";
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[userIdentifier]"] = "asc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      setUsers(undefined);
      const response = await requestApi({
        method: GET,
        path: F_COMPTET_USER_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = 0;
        const newUsers = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              userIdentifier:
                response.content["hydra:member"][0].userIdentifier +
                "_" +
                maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setUsers(newUsers);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
      users,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setUsers(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setUsers(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const handleChangeSelect = React.useCallback(
    (prop: keyof FormState) => (event: SelectChangeEvent) => {
      let searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject = { search: searchParamsObject.search ?? "" };
      if ((event.target.value as string) !== "") {
        searchParamsObject.search = "";
      }
      const [key, value] = (event.target.value as string).split("=");
      if (!!value) {
        searchParamsObject[key] = value;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const onUserTransformed = React.useCallback(
    (userIdentifier: string) => {
      setFComptetUser(undefined);
      setSearchParams(
        {
          search: userIdentifier,
        },
        {
          replace: true,
        }
      );
    },
    [setSearchParams]
  );

  const setSearchParam = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (!!search || !!searchParamsObject.search) {
      setSearchParams(
        {
          ...searchParamsObject,
          ...{ search: search },
        },
        {
          replace: true,
        }
      );
    }
  }, [search, searchParams, setSearchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const timeoutTyping = setTimeout(() => {
      setSearchParam();
    }, 500);
    return () => clearTimeout(timeoutTyping);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  let selectValue = "";
  if (searchParamsObject.hasOwnProperty("type")) {
    selectValue = "type=" + searchParamsObject.type;
  }
  if (searchParamsObject.hasOwnProperty("ctControlenc")) {
    selectValue = "ctControlenc=" + searchParamsObject.ctControlenc;
  }
  return (
    <>
      <DrawerComponent
        open={!!fComptetUser}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={
          <UserFormComponent
            fComptetUser={fComptetUser}
            setFComptetUser={setFComptetUser}
            successFunction={onUserTransformed}
            closeParent={handleDrawerClose}
          />
        }
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ width: "100%" }}
            label={t("word.search")}
            fullWidth
            autoComplete="off"
            type="text"
            value={search}
            onChange={handleChange("search")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="user-status">{t("word.status")}</InputLabel>
            <Select
              labelId="user-status"
              value={selectValue}
              label={t("word.status")}
              onChange={handleChangeSelect("status")}
            >
              <MenuItem value="">
                <em>{t("word.nothing")}</em>
              </MenuItem>
              <MenuItem value={"ctControlenc=0"}>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <HowToRegIcon color="success" sx={{ mr: 1 }} />
                  {t("word.ctControlenc.0")}
                </Typography>
              </MenuItem>
              <MenuItem value={"ctControlenc=1"}>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <HowToRegIcon color="info" sx={{ mr: 1 }} />
                  {t("word.ctControlenc.1")}
                </Typography>
              </MenuItem>
              <MenuItem value={"ctControlenc=2"}>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <CloseIcon color="error" sx={{ mr: 1 }} />
                  {t("word.ctControlenc.2")}
                </Typography>
              </MenuItem>
              <MenuItem value={"type=B"}>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <TransformIcon color="warning" sx={{ mr: 1 }} />
                  {t("sentence.administrationNotYetTransformAction")}
                  {nbAdministrationNotYetTransform !== undefined &&
                    nbAdministrationNotYetTransform > 0 && (
                      <Chip
                        label={nbAdministrationNotYetTransform ?? 0}
                        variant="outlined"
                        color="primary"
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        {searchParamsObject.itemsPerPage && searchParamsObject.page && (
          // https://mui.com/x/react-data-grid/components/#pagination
          <DataGrid
            initialState={{
              sorting: {
                sortModel: getSortModel(),
              },
            }}
            getRowHeight={() => "auto"}
            loading={!users}
            rows={users ?? []}
            onSortModelChange={onSortModelChange}
            sortingMode="server"
            page={Number(searchParamsObject.page) - 1}
            rowsPerPageOptions={[10, 25, 50]}
            getRowId={(row) => row.userIdentifier}
            pageSize={Number(searchParamsObject.itemsPerPage)}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            rowCount={totalItems}
            columns={columns}
            autoHeight={true}
            disableExtendRowFullWidth={true}
            localeText={getLocaleDataGrid(i18n.language)}
          />
        )}
      </Box>
    </>
  );
});

export default UserAdminComponent;
