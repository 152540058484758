import React, { forwardRef, useImperativeHandle, useRef } from "react";
import FilterDoTypeComponent from "./FilterDoTypeComponent";
import FilterKeywordsFDocenteteComponent from "./FilterKeywordsFDocenteteComponent";
import FilterDateFDocenteteComponent from "./FilterDateFDocenteteComponent";
import AddFilterFDocenteteComponent from "./AddFilterFDocenteteComponent";
import CustomFilterFDocenteteComponent from "./CustomFilterFDocenteteComponent";

interface State {
  apply: Function;
}

const FilterFDocentetesComponent = React.memo(
  forwardRef(({ apply }: State, ref) => {
    const doTypeRef: any = useRef();
    const keywordRef: any = useRef();
    const datesRef: any = useRef();
    const fDocenteteFilterRef: any = useRef();

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...doTypeRef.current?.getValue(),
          ...keywordRef.current?.getValue(),
          ...datesRef.current?.getValue(),
          ...fDocenteteFilterRef.current?.getValue(),
        };
      },
    }));

    return (
      <>
        <FilterDoTypeComponent apply={apply} ref={doTypeRef} />
        <FilterKeywordsFDocenteteComponent apply={apply} ref={keywordRef} />
        <FilterDateFDocenteteComponent apply={apply} ref={datesRef} />
        <CustomFilterFDocenteteComponent
          apply={apply}
          ref={fDocenteteFilterRef}
        />
        <AddFilterFDocenteteComponent />
      </>
    );
  })
);

export default FilterFDocentetesComponent;
