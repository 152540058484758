import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ManualCronInterface } from "../../../interfaces/TaskInterface";

interface State {
  cron: ManualCronInterface | undefined;
}

const DialogCronComponent: React.FC<State> = React.memo(({ cron }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {cron && cron.started && cron.ended && (
        <>
          <Typography>
            {t("word.startedAt") + ": "}
            {new Date(cron.started).toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "America/Cuiaba",
            })}
          </Typography>
          <Typography>
            {t("word.endedAt") + ": "}
            {new Date(cron.started).toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "America/Cuiaba",
            })}
          </Typography>
          <Typography>{t("word.logs") + ":"}</Typography>
          <pre>{cron.output}</pre>
        </>
      )}
    </>
  );
});

export default DialogCronComponent;
