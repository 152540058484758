import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { FARTSTOCK_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import CopyClipboardComponent from "../CopyClipboardComponent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Autocomplete, TextField } from "@mui/material";
import { FDepotemplInterface } from "../../../interfaces/FArticleInterface";
import CloseIcon from "@mui/icons-material/Close";
import { ArrivageInterface } from "../../../interfaces/ArrivageInterface";

interface State {
  emplacement: string | undefined | null;
  disabled?: boolean;
  fArtstockCbmarq: number | undefined;
  arRef: string;
  setArrivage: Function;
  focusMainSearch: Function;
}

interface FormState {
  emplacement: InputInterface;
}

const clone = require("clone");

const UpdateEmplacementComponent: React.FC<State> = React.memo(
  ({
    emplacement,
    disabled,
    fArtstockCbmarq,
    arRef,
    setArrivage,
    focusMainSearch,
  }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const fDepotempls = useAppSelector(
      (state: RootState) => state.globalState.fDepotempls
    );
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        emplacement: {
          value: fDepotempls?.find((e) => e.dpCode === emplacement) ?? null,
          error: "",
        },
      };
    }, [emplacement, fDepotempls]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const handleEdit = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        async (
          event: SyntheticEvent<Element, Event>,
          value: FDepotemplInterface | null
        ) => {
          if (value && value.dpCode !== emplacement && fArtstockCbmarq) {
            setLoading(true);
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
            const response = await requestApi({
              method: PATCH,
              path: FARTSTOCK_URL + "/" + fArtstockCbmarq,
              allowError: true,
              token: token,
              body: {
                dpNoprincipal: value?.dpNo,
              },
            });
            if (response.statusCode === 200) {
              setArrivage((a: ArrivageInterface) => {
                const fDocenteteLigneArrivage =
                  a.data.fDocenteteLigneArrivages?.find(
                    (f) => f.arRef === arRef
                  );
                if (fDocenteteLigneArrivage) {
                  fDocenteteLigneArrivage.emplacement = value.dpCode;
                  return clone(a);
                }
                return a;
              });
            } else if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"));
            } else {
              for (let message of getErrorApi(response.content)) {
                toastr.error(t("word.error"), t(message));
              }
            }
          }
          setEdit(false);
          setLoading(false);
        },
      [arRef, emplacement, fArtstockCbmarq, setArrivage, t, token]
    );

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [emplacement, edit, fDepotempls]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box>
        {edit ? (
          <Box sx={{ display: "flex" }}>
            <Autocomplete
              size="small"
              fullWidth
              value={values.emplacement.value}
              options={fDepotempls ?? []}
              disabled={loading}
              onChange={handleChangeAutocomplete("emplacement")}
              isOptionEqualToValue={(
                option: FDepotemplInterface,
                value: FDepotemplInterface
              ) => option.cbmarq === value.cbmarq}
              getOptionLabel={(option: FDepotemplInterface) => option.dpCode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      minWidth: "60px !important",
                    },
                  }}
                />
              )}
            />
            <IconButton color="inherit" onClick={handleEdit}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={emplacement ?? ""}
              afterCopy={focusMainSearch}
            />
            <IconButton
              color="inherit"
              onClick={handleEdit}
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
          </>
        )}
      </Box>
    );
  }
);

export default UpdateEmplacementComponent;
