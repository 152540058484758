import * as React from "react";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  FArticleImageInterface,
  FArticleInterface,
} from "../../../interfaces/FArticleInterface";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CropperComponent from "../file/CropperComponent";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { swapCard } from "../../../helpers/CardFormat";
import ImageQWantComponent from "./qWant/ImageQWantComponent";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { QWantImageInterface } from "./qWant/SingleImageQWantComponent";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setEdit?: any;
  setFArticle: Function;
  hidden?: boolean;
  temporaryIcecat?: any;
  createFrom?: FArticleInterface;
  getCreateFromCodebarreRefCons?: Function;
}

export interface CropperImageInterface {
  ref: any;
  file?: File;
  url?: string;
}

const FArticleImageEditComponent = React.memo(
  forwardRef(
    (
      {
        fArticle,
        edit,
        setEdit,
        setFArticle,
        hidden,
        temporaryIcecat,
        createFrom,
        getCreateFromCodebarreRefCons,
      }: State,
      ref
    ) => {
      const getImages = React.useCallback(() => {
        return [...(fArticle?.fArticleProp?.images ?? [])];
      }, [fArticle?.fArticleProp?.images]);
      const [images, setImages] = React.useState<
        (FArticleImageInterface | CropperImageInterface | undefined)[]
      >(getImages());
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();
      const [loading, setLoading] = React.useState(false);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );

      const removeImage = React.useCallback(
        (indexImage: number) => () => {
          setImages((x) => {
            x = x.filter((y, indexY) => indexY !== indexImage);
            return [...x];
          });
        },
        []
      );

      const newImageAdded = React.useCallback(() => {
        setImages((x) => {
          return [...x, { ref: React.createRef() }];
        });
      }, []);

      const customAddImage = React.useCallback(
        (data: QWantImageInterface[]) => {
          setImages(
            (
              x: (CropperImageInterface | FArticleImageInterface | undefined)[]
            ) => {
              for (let image of data) {
                x.splice(0, 0, {
                  ref: React.createRef(),
                  url: image.media,
                });
              }
              return [...x];
            }
          );
        },
        []
      );

      const addWithUrl = React.useCallback((url: string) => {
        setImages((x) => {
          x.splice(x.length - 1, 0, {
            ref: React.createRef(),
            url: url,
          });
          return [...x];
        });
      }, []);

      const onDragEnd = React.useCallback((result: DropResult) => {
        if (
          result.destination === null ||
          result.destination === undefined ||
          result.source === null ||
          result.source === undefined
        ) {
          return;
        }
        setImages((thisRowRef) => {
          thisRowRef = swapCard(thisRowRef, result);
          return thisRowRef;
        });
      }, []);

      const getValue = React.useCallback(async (): Promise<any> => {
        const thisImages: any[] = [];
        const imagesApi: any[] = [];
        const files: any[] = [];
        let i = 1;
        let sort = 0;
        for (const thisImage of images) {
          if (thisImage === undefined || thisImage === null) {
            continue;
          }
          sort++;
          if (thisImage.hasOwnProperty("ref")) {
            // @ts-ignore
            const file = await thisImage.ref.current.getValue();
            if (file === undefined || file === null) {
              continue;
            }
            const filename = "file" + i;
            imagesApi.push({
              file: filename,
              sort: sort,
            });
            files.push({
              file: file,
              filename: filename,
            });
            i++;
          } else {
            thisImages.push({
              // @ts-ignore
              name: thisImage.name,
              sort: sort,
            });
          }
        }
        return {
          images: [...thisImages, ...imagesApi],
          files: files,
        };
      }, [images]);

      const save = React.useCallback(async () => {
        if (!fArticle?.arRef) {
          setEdit(false);
          return;
        }
        setLoading(true);
        // @ts-ignore
        const images = await getValue();
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: {
              images: images.images,
            },
          })
        );
        for (const file of images.files) {
          fArticleApi.append(file.filename, file.file);
        }
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setEdit, setFArticle, t, token]);

      useImperativeHandle(ref, () => ({
        async getValue() {
          return getValue();
        },
        addImages(newImages: string[]) {
          setEdit(true);
          setTimeout(() => {
            setImages(
              (
                x: (
                  | CropperImageInterface
                  | FArticleImageInterface
                  | undefined
                )[]
              ) => {
                for (let image of newImages) {
                  x.splice(0, 0, {
                    ref: React.createRef(),
                    url: image,
                  });
                }
                return [...x];
              }
            );
          });
        },
      }));

      useEffect(() => {
        setImages(getImages());
      }, [fArticle?.fArticleProp?.images, hidden]); // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          const technicalSheet = generateTechnicalSheet(temp, 1);
          if (technicalSheet?.images && technicalSheet.images.length > 0) {
            setImages([]);
            setTimeout(() => {
              for (const image of technicalSheet.images) {
                addWithUrl(image.Pic);
              }
            });
          }
        }
      }, [temporaryIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {!hidden && (
            <>
              {!edit && setEdit && (
                <Box sx={{ textAlign: "center" }}>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={setEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              )}
              <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{ overflow: "auto" }}>
                  <Droppable droppableId="fArticleImage" direction="horizontal">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "max-content",
                        }}
                      >
                        {images.map((image, indexImage) => (
                          <Draggable
                            key={indexImage}
                            draggableId={indexImage.toString()}
                            index={indexImage}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Box sx={{ textAlign: "center" }}>
                                  <span
                                    style={{
                                      cursor: "move",
                                      marginRight: 1,
                                    }}
                                    {...provided.dragHandleProps}
                                  >
                                    <MenuIcon />
                                  </span>
                                </Box>
                                <Box
                                  key={indexImage}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 1,
                                  }}
                                >
                                  {image && image.hasOwnProperty("name") ? (
                                    <img
                                      src={
                                        (process.env.REACT_APP_API_URL ?? "") +
                                        getUrlFArticleImage(
                                          fArticle?.arRef,
                                          // @ts-ignore
                                          image,
                                          IMAGE_SIZE_SMALL
                                        )
                                      }
                                      style={{
                                        maxWidth: "100%",
                                        border: "1px dashed gray",
                                      }}
                                      alt={
                                        "[" +
                                        fArticle?.arRef +
                                        "] " +
                                        fArticle?.arDesign
                                      }
                                      loading="lazy"
                                    />
                                  ) : image !== undefined ? (
                                    <CropperComponent
                                      // @ts-ignore
                                      key={image.url}
                                      // @ts-ignore
                                      ref={image.ref}
                                      // @ts-ignore
                                      url={image.url}
                                      // @ts-ignore
                                      indexImage={image.url}
                                      addWithUrl={addWithUrl}
                                      xs={isSmall ? 12 : 8}
                                      hideButton={true}
                                      setImages={setImages}
                                        // @ts-ignore
                                      initFile={image.file}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <Box sx={{ alignItems: "flex-start" }}>
                                    <IconButton
                                      onClick={removeImage(indexImage)}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Box>
              </DragDropContext>
              <CropperComponent
                imageAdded={newImageAdded}
                addWithUrl={addWithUrl}
                xs={isSmall ? 12 : 8}
                multiple={true}
              />
              <ImageQWantComponent
                initKeywords={[
                  fArticle?.constructeurRef ?? "",
                  fArticle?.arCodebarre ?? "",
                ]}
                customAddImage={customAddImage}
                createFrom={createFrom}
                getCreateFromCodebarreRefCons={getCreateFromCodebarreRefCons}
              />
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleImageEditComponent;
