import React, { forwardRef, useImperativeHandle } from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  FilterFDocenteteInterface,
  FilterFDocenteteOptionInterface,
} from "../../../interfaces/FDocenteteInterface";
import {
  FDOCENTETE_FILTER_TYPE_CHECKBOX,
  FDOCENTETE_FILTER_TYPE_TEXT,
} from "../../../utils/DoceneteteUtils";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import { DialogProps, TextField, Tooltip, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { FormFilterFDocenteteComponent } from "./AddFilterFDocenteteComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE } from "../../../utils/MethodUtils";
import { FILTER_FDOCENTETE_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { set } from "../../../app/globalSlice";
import Divider from "@mui/material/Divider";

const clone = require("clone");

interface State {
  apply: Function;
}

interface State2 {
  filterFDocentete: FilterFDocenteteInterface;
  apply: Function;
}

interface State3 {
  type: string;
  filterFDocenteteOption: FilterFDocenteteOptionInterface;
  apply: Function;
  onCheckedChange2: Function;
}

interface FilterFDocenteteOptionRefInterface {
  filterFDocenteteOption: FilterFDocenteteOptionInterface;
  ref: any;
}

interface FilterFDocenteteRefInterface {
  filterFDocentete: FilterFDocenteteInterface;
  ref: any;
}

const CustomFilterFDocenteteOptionComponent = React.memo(
  forwardRef(
    (
      { type, filterFDocenteteOption, apply, onCheckedChange2 }: State3,
      ref
    ) => {
      const field = "filterFDocenteteOption-" + filterFDocenteteOption.id;
      const notField = "notFilterFDocenteteOption-" + filterFDocenteteOption.id;
      const theme = useTheme();
      const [searchParams] = useSearchParams();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const getDefaultValue = React.useCallback(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        if (searchParamsObject.hasOwnProperty(field)) {
          if (type === FDOCENTETE_FILTER_TYPE_CHECKBOX) {
            return true;
          }
          return JSON.parse(searchParamsObject[field]).v;
        }
        if (searchParamsObject.hasOwnProperty(notField)) {
          if (type === FDOCENTETE_FILTER_TYPE_CHECKBOX) {
            return false;
          }
          return JSON.parse(searchParamsObject[field]).v;
        }
        return type === FDOCENTETE_FILTER_TYPE_CHECKBOX ? null : "";
      }, [field, notField, searchParams, type]);
      const [inputValue, setInputValue] = React.useState(getDefaultValue());
      const [tempInputValue, setTempInputValue] = React.useState(
        getDefaultValue()
      );

      const handleChange = React.useCallback(
        (newChecked: boolean | undefined) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            if (newChecked === inputValue) {
              setInputValue(null);
            } else {
              setInputValue(newChecked);
            }
          },
        [inputValue]
      );

      const onCheckedChange = React.useCallback(() => {
        onCheckedChange2();
        if (!isSmall) {
          apply();
        }
      }, [apply, isSmall, onCheckedChange2]);

      useImperativeHandle(ref, () => ({
        getValue() {
          if (inputValue) {
            return {
              [field]:
                type === FDOCENTETE_FILTER_TYPE_TEXT
                  ? JSON.stringify({ v: inputValue, symbol: "like" })
                  : JSON.stringify({ v: "1", symbol: "=" }),
            };
          } else if (inputValue === false) {
            return {
              [notField]:
                type === FDOCENTETE_FILTER_TYPE_TEXT
                  ? JSON.stringify({ v: inputValue, symbol: "not like" })
                  : JSON.stringify({ v: "1", symbol: "!=" }),
            };
          }
          return {};
        },
      }));

      React.useEffect(() => {
        onCheckedChange();
      }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setInputValue(getDefaultValue());
        setTempInputValue(getDefaultValue());
      }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          setInputValue(tempInputValue);
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [tempInputValue]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {type === FDOCENTETE_FILTER_TYPE_TEXT ? (
            <>
              <TextField
                fullWidth={true}
                autoComplete="off"
                sx={{
                  width: "100%",
                }}
                required
                type="text"
                value={tempInputValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTempInputValue(event.target.value);
                }}
                label={filterFDocenteteOption.name}
              />
            </>
          ) : (
            <FormGroup
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputValue}
                    onChange={handleChange(true)}
                  />
                }
                label={filterFDocenteteOption.name}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputValue === false}
                    onChange={handleChange(false)}
                    sx={{ paddingY: 0, paddingLeft: 0 }}
                  />
                }
                label={
                  <Typography component="span">
                    {filterFDocenteteOption.name}
                    <Typography component="span" sx={{ color: "red" }}>
                      {" exclus"}
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          )}
        </>
      );
    }
  )
);

const CustomFilterFDocenteteSingleComponent = React.memo(
  forwardRef(({ filterFDocentete, apply }: State2, ref) => {
    const theme = useTheme();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const filterFDocentetes = useAppSelector(
      (state: RootState) => state.globalState.filterFDocentetes
    );
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const getFilterFDocenteteOptionsRef = React.useCallback(() => {
      return filterFDocentete.filterFDocenteteOptions.map(
        (filterFDocenteteOption) => {
          return {
            filterFDocenteteOption: filterFDocenteteOption,
            ref: React.createRef(),
          };
        }
      );
    }, [filterFDocentete.filterFDocenteteOptions]);
    const [filterFDocenteteOptionsRef, setFilterFDocenteteOptionsRef] =
      React.useState<FilterFDocenteteOptionRefInterface[]>(
        getFilterFDocenteteOptionsRef()
      );
    const getNbSelected = React.useCallback(() => {
      return Object.keys(
        filterFDocenteteOptionsRef
          .filter((f) => f.ref.current)
          .map((f) => f.ref.current.getValue())
          .reduce((r, c) => Object.assign(r, c), {})
      ).length;
    }, [filterFDocenteteOptionsRef]);
    const [nbSelected, setNbSelected] = React.useState<number>(getNbSelected());
    const [edit, setEdit] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const onCheckedChange = React.useCallback(() => {
      setNbSelected(getNbSelected());
    }, [getNbSelected]);
    const onDelete = React.useCallback(async () => {
      setLoadingDelete(true);
      const response = await requestApi({
        method: DELETE,
        path: FILTER_FDOCENTETE_URL + "/" + filterFDocentete.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        const newFilterFDocentetes: FilterFDocenteteInterface[] =
          clone(filterFDocentetes);
        dispatch(
          set({
            filterFDocentetes: newFilterFDocentetes.filter(
              (f) => f.id !== filterFDocentete.id
            ),
          })
        );
        setOpenDelete(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingDelete(false);
    }, [dispatch, filterFDocentete.id, filterFDocentetes, t, token]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return filterFDocenteteOptionsRef
          .map((f) => f.ref.current?.getValue())
          .filter((f) => f !== undefined)
          .reduce((r, c) => Object.assign(r, c), {});
      },
    }));

    React.useEffect(() => {
      setFilterFDocenteteOptionsRef(getFilterFDocenteteOptionsRef());
    }, [filterFDocentete.filterFDocenteteOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {edit ? (
          <FormFilterFDocenteteComponent
            setCreating={setEdit}
            filterFDocentete={filterFDocentete}
          />
        ) : (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              sx={{
                backgroundColor: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  marginTop: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {filterFDocentete.name}
                {nbSelected > 0 && (
                  <Typography
                    component="span"
                    className="badge"
                    sx={{ marginLeft: 1 }}
                  >
                    {nbSelected}
                  </Typography>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filterFDocenteteOptionsRef.map(
                (filterFDocenteteOption, indexFilterFDocenteteOption) => (
                  <React.Fragment key={indexFilterFDocenteteOption}>
                    {indexFilterFDocenteteOption > 0 && <Divider />}
                    <CustomFilterFDocenteteOptionComponent
                      type={filterFDocentete.type}
                      filterFDocenteteOption={
                        filterFDocenteteOption.filterFDocenteteOption
                      }
                      onCheckedChange2={onCheckedChange}
                      ref={filterFDocenteteOption.ref}
                      apply={apply}
                    />
                  </React.Fragment>
                )
              )}
              {!edit && (
                <>
                  <Dialog
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    onClose={() => {
                      if (loadingDelete) {
                        return;
                      }
                      setOpenDelete(false);
                    }}
                    open={openDelete}
                  >
                    <DialogContent>
                      <Typography>{t("sentence.deleteFilter")}</Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "space-between" }}>
                      <LoadingButton
                        disabled={loadingDelete}
                        onClick={() => {
                          setOpenDelete(false);
                        }}
                      >
                        {t("word.no")}
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        loading={loadingDelete}
                        onClick={onDelete}
                      >
                        {t("word.yes")}
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip title={t("word.delete")}>
                      <IconButton
                        onClick={() => {
                          setOpenDelete(true);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("word.edit.word")}>
                      <IconButton onClick={() => setEdit((e) => !e)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  })
);

const CustomFilterFDocenteteComponent = React.memo(
  forwardRef(({ apply }: State, ref) => {
    const filterFDocentetes = useAppSelector(
      (state: RootState) => state.globalState.filterFDocentetes
    );
    const getFilterFDocentetesRef = React.useCallback(() => {
      return (
        filterFDocentetes?.map((filterFDocentete) => {
          return {
            filterFDocentete: filterFDocentete,
            ref: React.createRef(),
          };
        }) ?? []
      );
    }, [filterFDocentetes]);
    const [filterFDocentetesRef, setFilterFDocentetesRef] = React.useState<
      FilterFDocenteteRefInterface[]
    >(getFilterFDocentetesRef());

    useImperativeHandle(ref, () => ({
      getValue() {
        return filterFDocentetesRef
          .map((f) => f.ref.current.getValue())
          .reduce((r, c) => Object.assign(r, c), {});
      },
    }));

    React.useEffect(() => {
      setFilterFDocentetesRef(getFilterFDocentetesRef());
    }, [filterFDocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {filterFDocentetesRef?.map(
          (filterFDocenteteRef, indexFilterFDocentete) => (
            <CustomFilterFDocenteteSingleComponent
              key={indexFilterFDocentete}
              filterFDocentete={filterFDocenteteRef.filterFDocentete}
              ref={filterFDocenteteRef.ref}
              apply={apply}
            />
          )
        )}
      </>
    );
  })
);

export default CustomFilterFDocenteteComponent;
