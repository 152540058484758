import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { styled } from "@mui/material/styles";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { IMAGE_SIZE_LOGO, SIZES_FARTICLES } from "../../../utils/FileUtils";
import { resizeImage } from "../../../helpers/ResizeImage";
import { QWANT_FILE } from "../../../utils/UrlsUtils";
import { CircularProgress, Grid } from "@mui/material";
import { toastr } from "react-redux-toastr";
import { GridSize } from "@mui/material/Grid/Grid";
import { CropperImageInterface } from "../fArticle/FArticleImageEditComponent";

const Input = styled("input")({
  display: "none",
});

interface State {
  indexImage?: string;
  multiple?: boolean;
  deleteImage?: Function;
  imageAdded?: Function;
  minCropBox?: number;
  aspectRatio?: number;
  url?: string | undefined;
  xs?: boolean | GridSize;
  addWithUrl?: Function;
  hideButton?: boolean;
  setImages?: Function;
  initFile?: File;
}

// https://codesandbox.io/s/wonderful-pine-i7fs3?file=/src/Demo.tsx:0-2418
// https://github.com/fengyuanchen/cropperjs#options
// https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
const CropperComponent = React.memo(
  forwardRef(
    (
      {
        indexImage,
        deleteImage,
        imageAdded,
        minCropBox,
        aspectRatio,
        url,
        xs,
        multiple,
        addWithUrl,
        hideButton,
        setImages,
        initFile,
      }: State,
      ref
    ) => {
      const [image, setImage] = React.useState(undefined);
      const [filename, setFilename] = React.useState("");
      const [mimeType, setMimeType] = React.useState("");
      const [cropper, setCropper] = React.useState<any>();
      const [loading, setLoading] = React.useState<boolean>(false);
      const inputFileRef: any = useRef();
      const { t } = useTranslation();

      const hasImage = React.useCallback(() => {
        return image !== undefined;
      }, [image]);

      const addFile = React.useCallback(
        async (file: File) => {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result as any);
          };
          reader.readAsDataURL(
            await resizeImage({
              file: file,
              maxSize: Math.round(Math.max(...SIZES_FARTICLES) * 1.2),
            })
          );
          setFilename(file.name);
          setMimeType(file.type);
          if (setImages) {
            setImages((images: CropperImageInterface[]) => {
              let index = images.findIndex((i) => i.url === indexImage);
              if (index >= 0) {
                images[index].file = file;
                return [...images];
              }
              return images;
            });
          }
        },
        [indexImage, setImages]
      );

      const addFileWithUrl = React.useCallback(async () => {
        if (!url) {
          return;
        }
        setLoading(true);
        let res;
        if (url.startsWith("blob")) {
          res = await fetch(encodeURI(url));
        } else {
          res = await fetch(
            process.env.REACT_APP_API_URL +
              QWANT_FILE +
              "?url=" +
              encodeURI(url)
          );
        }
        if (res.status !== 200) {
          toastr.error(t("word.error"), t("error.tryAgain"));
          setLoading(false);
          window.open(url, "_blank")?.focus();
          return;
        }
        const blob = await res.blob();
        await addFile(
          new File([blob], "name.jpg", {
            type: res.headers.get("Content-Type") ?? "image/jpeg",
          })
        );
        setLoading(false);
      }, [addFile, t, url]);

      const onChange = React.useCallback(
        (e: any) => {
          // todo if image smallest dimension < 390 then write error message front, pass in param of CropperComponent
          e.preventDefault();
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          if (addWithUrl && !hasImage()) {
            for (const file of [...files]) {
              addWithUrl(URL.createObjectURL(file));
            }
            return;
          }
          if (imageAdded !== undefined && !hasImage()) {
            imageAdded();
          }
          addFile(files[0]);
        },
        [addFile, addWithUrl, hasImage, imageAdded]
      );

      const resetFile = React.useCallback(() => {
        setImage(undefined);
        setFilename("");
        setMimeType("");
        inputFileRef.current.value = "";
        if (deleteImage !== undefined) {
          if (indexImage !== undefined) {
            deleteImage(indexImage);
          } else {
            deleteImage();
          }
        }
      }, [deleteImage, indexImage]);

      useImperativeHandle(ref, () => ({
        async getValue() {
          if (cropper !== undefined && image !== undefined) {
            // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f#answer-38935990
            return (
              (await fetch(cropper.getCroppedCanvas().toDataURL())
                .then((res) => {
                  return res.arrayBuffer();
                })
                .then((buf) => {
                  return new File([buf], filename, { type: mimeType });
                })) ?? null
            );
          }
          return undefined;
        },
      }));

      React.useEffect(() => {
        if (initFile) {
          if (!image) {
            addFile(initFile);
            setLoading(false);
          }
        } else {
          addFileWithUrl();
        }
      }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <label>
                <Input
                  accept="image/*"
                  type="file"
                  multiple={!!multiple && !hasImage()}
                  onChange={onChange}
                  ref={inputFileRef}
                />
                {hideButton !== true && (
                  <Button variant="contained" component="span">
                    {hasImage()
                      ? t("action.edit.image")
                      : t("action.new.image")}
                  </Button>
                )}
              </label>
            </Stack>
            {hasImage() && hideButton !== true && (
              <IconButton
                onClick={resetFile}
                sx={{
                  color: "black",
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
          {loading && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {image !== undefined && (
            <Grid container spacing={1}>
              <Grid item xs={xs}>
                <Cropper
                  style={{ height: "auto", width: "100%", maxHeight: 400 }}
                  zoomable={true}
                  initialAspectRatio={1}
                  src={image}
                  viewMode={0}
                  aspectRatio={aspectRatio ?? 1}
                  minCropBoxHeight={minCropBox ?? IMAGE_SIZE_LOGO}
                  minCropBoxWidth={minCropBox ?? IMAGE_SIZE_LOGO}
                  background={true}
                  responsive={false}
                  // https://stackoverflow.com/a/59218152/6824121
                  autoCropArea={1}
                  dragMode="move"
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  )
);

export default CropperComponent;
