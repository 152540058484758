import React, { useImperativeHandle, useRef } from "react";
import {
  ArrivageFDocligneInterface,
  ArrivageInterface,
  FDocenteteLigneArrivageInterface,
  FDocenteteLigneArrivageSerialNumberInterface,
} from "../../../interfaces/ArrivageInterface";
import {
  Card,
  CardContent,
  Chip,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"; // https://stackoverflow.com/a/71046070/6824121
import { getUrlImageDirectory } from "../../../helpers/FileHelper";
import CopyClipboardComponent from "../CopyClipboardComponent";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UpdateSuiviStockComponent from "./UpdateSuiviStockComponent";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  SUIVI_STOCK_TYPE_LOT,
  SUIVI_STOCK_TYPE_SERIE,
} from "../../../utils/FDoceneteteUtils";
import CloseIcon from "@mui/icons-material/Close";
import { scrollToArticleFDocligne } from "./EntreeStockArrivageComponent";
import { useTheme } from "@mui/material/styles";
import {
  FArticleInterface,
  FArtstockInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import UpdateWeightComponent from "./UpdateWeightComponent";
import { Link } from "react-router-dom";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import UpdateColisageComponent from "./UpdateColisageComponent";
import theme from "../../../app/Theme";
import UpdateEmplacementComponent from "./UpdateEmplacementComponent";
import FArticleRefConstructeurComponent from "../fArticle/FArticleRefConstructeurComponent";
import FArticleArCodebarreComponent from "../fArticle/FArticleArCodebarreComponent";
import ticketSound from "../../../assets/ticket.mp3";
import { formatReference } from "../../../helpers/FarticleFormat";
import CircleIcon from "@mui/icons-material/Circle";
import { green, red } from "@mui/material/colors";

interface State {
  fDocenteteLigneArrivage: FDocenteteLigneArrivageInterface;
  focusMainSearch: Function;
  fDoclignes: ArrivageFDocligneInterface[];
  running: boolean;
  index: number;
  setArrivage: Function;
  focusSerialNumberIndex: number | undefined;
}

interface State2 {
  fDocenteteLigneArrivage: FDocenteteLigneArrivageInterface;
  fDoclignesQuantity: number;
  arSuivistock: number;
  running: boolean;
  focusMainSearch: Function;
}

interface State3 {
  serialNumbers: (FDocenteteLigneArrivageSerialNumberInterface | undefined)[];
  indexSerialNumber: number;
  running: boolean;
  getAllSerialNumbers: Function;
  validateSerial: Function;
}

interface State4 extends State3 {
  maxCanAdd: number;
  removeSerialNumber: Function;
  updateSerialNumberQuantity: Function;
}

interface FormState {
  quantity: InputInterface;
}

interface FormState2 {
  serial: InputInterface;
  quantity: InputInterface;
  sync: InputInterface;
}

const clone = require("clone");

const EntreeStockFArticleSerieComponent = React.memo(
  React.forwardRef(
    (
      {
        serialNumbers,
        indexSerialNumber,
        running,
        getAllSerialNumbers,
        validateSerial,
      }: State3,
      ref
    ) => {
      const searchRef: any = useRef();

      const getHasDuplicate = (serial: string) => {
        return (
          getAllSerialNumbers().find(
            (s: FDocenteteLigneArrivageSerialNumberInterface, i: number) =>
              s.serial === serial && i !== indexSerialNumber && s.serial !== ""
          ) !== undefined
        );
      };

      const getDefaultValue = (): FormState2 => {
        const serial = serialNumbers[indexSerialNumber]?.serial ?? "";
        const hasDuplicate = getHasDuplicate(serial);
        return {
          serial: {
            value: serial,
            error: hasDuplicate ? "error.duplicate" : "",
          },
          quantity: {
            value: 1,
            error: "",
          },
          sync: {
            value: serialNumbers[indexSerialNumber]?.sync ?? false,
            error: "",
          },
        };
      };
      const [values, setValues] = React.useState<FormState2>(getDefaultValue());

      const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            v.sync.value = false;
            v.serial.value = formatReference(
              (event.target as HTMLInputElement).value
            );
            v.serial.error = "";
            return { ...v };
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          if (!serialNumbers[indexSerialNumber]) {
            return null;
          }
          return {
            dlNo: serialNumbers[indexSerialNumber]?.dlNo,
            serial: values.serial.value.trim(),
            quantity: Number(values.quantity.value),
          };
        },
        focusSerial() {
          searchRef.current.querySelector("input").focus();
        },
      }));

      React.useEffect(
        () => {
          setValues(getDefaultValue());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          serialNumbers[indexSerialNumber]?.serial ?? // eslint-disable-line react-hooks/exhaustive-deps
            "",
          serialNumbers[indexSerialNumber]?.quantity, // eslint-disable-line react-hooks/exhaustive-deps
          serialNumbers[indexSerialNumber]?.sync, // eslint-disable-line react-hooks/exhaustive-deps
        ]
      );

      React.useEffect(() => {
        const hasDuplicate = getHasDuplicate(values.serial.value);
        if (hasDuplicate) {
          if (values.serial.error === "") {
            setValues((v) => {
              v.serial.error = "error.duplicate";
              return { ...v };
            });
          }
        } else if (values.serial.error !== "") {
          setValues((v) => {
            v.serial.error = "";
            return { ...v };
          });
        }
      }, [JSON.stringify(serialNumbers.map((s) => s?.serial))]); // eslint-disable-line react-hooks/exhaustive-deps

      const serialError =
        values.serial.value.trim() === "" || values.serial.error !== "";

      return (
        <>
          {serialNumbers[indexSerialNumber] && (
            <>
              {serialNumbers[indexSerialNumber]?.dlNo !== null ? (
                <Box>
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={serialNumbers[indexSerialNumber]?.serial ?? ""}
                  />
                </Box>
              ) : (
                <Box sx={{ display: "flex", width: "100%" }}>
                  <TextField
                    ref={searchRef}
                    autoComplete="off"
                    sx={{ width: "100%" }}
                    disabled={running}
                    inputProps={{
                      className: "updateArrivage",
                      sx: {
                        paddingY: 0,
                        paddingX: 0.5,
                        ...(serialError && {
                          color: theme.palette.error.main,
                        }),
                      },
                    }}
                    onFocus={(e) => e.target.select()}
                    type="text"
                    value={values.serial.value}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        validateSerial(indexSerialNumber);
                      }
                    }}
                    error={serialError}
                  />
                  <CircleIcon
                    sx={{
                      width: "15px",
                      color: values.sync.value ? green[500] : red[500],
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

const EntreeStockFArticleLotComponent = React.memo(
  React.forwardRef(
    (
      {
        serialNumbers,
        indexSerialNumber,
        maxCanAdd,
        removeSerialNumber,
        updateSerialNumberQuantity,
        running,
        getAllSerialNumbers,
        validateSerial,
      }: State4,
      ref
    ) => {
      const searchRef: any = useRef();
      const quantityRef: any = useRef();

      const getDefaultValue = React.useCallback((): FormState2 => {
        return {
          serial: {
            value: serialNumbers[indexSerialNumber]?.serial ?? "",
            error: "",
          },
          quantity: {
            value: serialNumbers[indexSerialNumber]?.quantity ?? "",
            error: "",
          },
          sync: {
            value: serialNumbers[indexSerialNumber]?.sync ?? false,
            error: "",
          },
        };
      }, [serialNumbers, indexSerialNumber]);
      const [values, setValues] = React.useState<FormState2>(getDefaultValue());

      const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          if (serialNumbers[indexSerialNumber]?.dlNo !== null) {
            return;
          }
          setValues((v) => {
            v.sync.value = false;
            v.serial.value = formatReference(
              (event.target as HTMLInputElement).value
            );
            return { ...v };
          });
        },
        [indexSerialNumber, serialNumbers]
      );

      const handleMinMax = React.useCallback(
        (quantity: number) => {
          if (quantity <= 0) {
            return 1;
          }
          const max = Number(values.quantity.value) + maxCanAdd;
          if (quantity > max) {
            return max;
          }
          return quantity;
        },
        [values.quantity.value, maxCanAdd]
      );

      const focusQuantity = React.useCallback(() => {
        quantityRef.current.focus();
        setTimeout(() => {
          quantityRef.current.select();
        });
      }, []);

      const handleChangeQuantity = React.useCallback(
        (addRemove: number) => {
          if (running) {
            return;
          }
          setValues((v) => {
            v.quantity.value = handleMinMax(
              Number(v.quantity.value) + addRemove
            );
            return { ...v };
          });
          focusQuantity();
          setTimeout(() => {
            updateSerialNumberQuantity();
          });
        },
        [focusQuantity, handleMinMax, running, updateSerialNumberQuantity]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          if (!serialNumbers[indexSerialNumber]) {
            return null;
          }
          return {
            dlNo: serialNumbers[indexSerialNumber]?.dlNo,
            serial: values.serial.value.trim(),
            quantity: Number(values.quantity.value),
          };
        },
        focusSerial() {
          searchRef.current.querySelector("input").focus();
        },
      }));

      React.useEffect(
        () => {
          setValues(getDefaultValue());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          serialNumbers[indexSerialNumber]?.serial ?? // eslint-disable-line react-hooks/exhaustive-deps
            "",
          serialNumbers[indexSerialNumber]?.quantity, // eslint-disable-line react-hooks/exhaustive-deps
          serialNumbers[indexSerialNumber]?.sync, // eslint-disable-line react-hooks/exhaustive-deps
        ]
      );

      const serialError =
        values.serial.value.trim() === "" || values.serial.error !== "";
      return (
        <>
          {serialNumbers[indexSerialNumber] && (
            <>
              {serialNumbers[indexSerialNumber]?.dlNo !== null ? (
                <Box>
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={serialNumbers[indexSerialNumber]?.serial ?? ""}
                  />
                  {serialNumbers[indexSerialNumber]?.quantity}
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    ref={searchRef}
                    autoComplete="off"
                    disabled={running}
                    sx={{ width: "100%" }}
                    inputProps={{
                      className: "updateArrivage",
                      sx: {
                        paddingY: 0,
                        paddingX: 0.5,
                        ...(serialError && {
                          color: theme.palette.error.main,
                        }),
                      },
                    }}
                    onFocus={(e) => e.target.select()}
                    type="text"
                    value={values.serial.value}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        focusQuantity();
                      }
                    }}
                    error={serialError}
                  />
                  <FormControl variant="outlined" required>
                    <OutlinedInput
                      type="number"
                      inputRef={quantityRef}
                      onFocus={(e) => e.target.select()}
                      value={values.quantity.value}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          validateSerial(indexSerialNumber);
                        }
                      }}
                      disabled={running}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let value = event.target.value;
                        setValues((v) => {
                          v.quantity.value =
                            value !== "" ? handleMinMax(Number(value)) : value;
                          return { ...v };
                        });
                        setTimeout(() => {
                          updateSerialNumberQuantity();
                        });
                      }}
                      inputProps={{
                        className: "updateArrivage",
                        sx: {
                          width: 98,
                          textAlign: "center",
                          padding: 0,
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleChangeQuantity(1);
                            }}
                          />
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <RemoveIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleChangeQuantity(-1);
                            }}
                          />
                        </InputAdornment>
                      }
                      error={Number(values.quantity.value) <= 0}
                    />
                  </FormControl>
                  {serialNumbers.length > 1 && (
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        if (running) {
                          return;
                        }
                        removeSerialNumber(indexSerialNumber);
                      }}
                    />
                  )}
                  <CircleIcon
                    sx={{
                      width: "15px",
                      color: values.sync.value ? green[500] : red[500],
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

const EntreeStockFArticleSerieLotComponent = React.memo(
  React.forwardRef(
    (
      {
        fDocenteteLigneArrivage,
        fDoclignesQuantity,
        arSuivistock,
        running,
        focusMainSearch,
      }: State2,
      ref
    ) => {
      const getSerialNumbers = React.useCallback((): (
        | FDocenteteLigneArrivageSerialNumberInterface
        | undefined
      )[] => {
        return (fDocenteteLigneArrivage.serialNumbers ?? []).map((f) => {
          return {
            ...f,
            ref: React.createRef(),
          };
        });
      }, [fDocenteteLigneArrivage.serialNumbers]);
      const [ticketAudio] = React.useState<HTMLAudioElement>(
        new Audio(ticketSound)
      );
      const [serialNumbers, setSerialNumbers] = React.useState<
        (FDocenteteLigneArrivageSerialNumberInterface | undefined)[]
      >(getSerialNumbers());
      const getSerialNumberQuantity = React.useCallback(
        (
          thisSerialNumbers: (
            | FDocenteteLigneArrivageSerialNumberInterface
            | undefined
          )[]
        ): number => {
          // @ts-ignore
          return (
            thisSerialNumbers
              .filter((s) => s)
              .map((s) => s!.quantity)
              .reduce((a, b) => Number(a) + Number(b), 0) ?? 0
          );
        },
        []
      );
      const [serialNumberQuantity, setSerialNumberQuantity] =
        React.useState<number>(getSerialNumberQuantity(serialNumbers));

      const getValue = React.useCallback(() => {
        return serialNumbers
          .filter((s) => s?.ref?.current)
          .map((s) => s!.ref.current.getValue())
          .filter((s) => s !== null);
      }, [serialNumbers]);

      const updateSerialNumberQuantity = React.useCallback(() => {
        setSerialNumberQuantity(
          getValue()
            .filter((s) => s)
            .map((s) => s!.quantity)
            .reduce((a, b) => Number(a) + Number(b), 0) ?? 0
        );
      }, [getValue]);

      const removeSerialNumber = React.useCallback(
        (indexSerialNumber: number) => {
          setSerialNumbers((v) => {
            v[indexSerialNumber] = undefined;
            return [...v];
          });
          setTimeout(() => {
            updateSerialNumberQuantity();
          });
        },
        [updateSerialNumberQuantity]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        focusFirstSerial() {
          for (const serialNumber of serialNumbers) {
            if (serialNumber?.serial === "" && serialNumber.dlNo === null) {
              serialNumber.ref.current.focusSerial();
              break;
            }
          }
        },
      }));

      React.useEffect(() => {
        const newSerialNumbers = getSerialNumbers();
        setSerialNumbers(newSerialNumbers);
        setSerialNumberQuantity(getSerialNumberQuantity(newSerialNumbers));
      }, [
        fDocenteteLigneArrivage.serialNumbers,
        getSerialNumberQuantity,
        getSerialNumbers,
      ]);

      // const maxCanAdd = fDoclignesQuantity - serialNumberQuantity; // if need to limit nb lot to found fDoclignes
      const maxCanAdd = fDocenteteLigneArrivage.dlQte - serialNumberQuantity;

      const addSerialLot = React.useCallback(
        (indexSerialNumber: number | undefined = undefined) => {
          let lastIndex = 0;
          for (let i = serialNumbers.length - 1; i >= 0; i--) {
            if (serialNumbers[i] !== undefined) {
              lastIndex = i;
              break;
            }
          }
          if (
            indexSerialNumber === undefined ||
            indexSerialNumber === lastIndex
          ) {
            setSerialNumbers((v) => {
              v.push({
                serial: "",
                dlNo: null,
                quantity: 1,
                ref: React.createRef(),
              });
              return [...v];
            });
            setTimeout(() => {
              updateSerialNumberQuantity();
            });
          }
          setTimeout(() => {
            let thisIndex = undefined;
            if (indexSerialNumber === undefined) {
              thisIndex = lastIndex + 1;
            } else {
              for (
                let i = indexSerialNumber + 1;
                i < serialNumbers.length;
                i++
              ) {
                if (serialNumbers[i] !== undefined) {
                  thisIndex = i;
                  break;
                }
              }
            }
            if (thisIndex !== undefined) {
              serialNumbers[thisIndex]?.ref.current.focusSerial();
            }
          });
        },
        [serialNumbers, updateSerialNumberQuantity]
      );

      const validateSerial = React.useCallback(
        (indexSerialNumber: number) => {
          const timeout = 300;
          if (arSuivistock === SUIVI_STOCK_TYPE_SERIE) {
            if (indexSerialNumber === serialNumbers.length - 1) {
              focusMainSearch();
              ticketAudio.play().then(() => {
                setTimeout(() => {
                  ticketAudio.play();
                }, timeout);
              });
            } else {
              serialNumbers[indexSerialNumber + 1]?.ref.current.focusSerial();
              ticketAudio.play();
            }
          } else if (arSuivistock === SUIVI_STOCK_TYPE_LOT) {
            if (maxCanAdd > 0) {
              addSerialLot(indexSerialNumber);
              ticketAudio.play();
            } else {
              focusMainSearch();
              ticketAudio.play().then(() => {
                setTimeout(() => {
                  ticketAudio.play();
                }, timeout);
              });
            }
          }
        },
        [
          arSuivistock,
          serialNumbers,
          focusMainSearch,
          ticketAudio,
          maxCanAdd,
          addSerialLot,
        ]
      );

      const nbSerialNumbersInBdd = serialNumbers.filter((s) => s?.dlNo).length;
      return (
        <>
          {(arSuivistock === SUIVI_STOCK_TYPE_SERIE ||
            arSuivistock === SUIVI_STOCK_TYPE_LOT) &&
            serialNumbers && (
              <>
                {arSuivistock === SUIVI_STOCK_TYPE_SERIE &&
                  // @ts-ignore
                  [...Array(serialNumbers.length).keys()].map((index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="caption">{index + 1}</Typography>
                      <EntreeStockFArticleSerieComponent
                        ref={serialNumbers[index]?.ref}
                        serialNumbers={serialNumbers}
                        indexSerialNumber={index}
                        getAllSerialNumbers={getValue}
                        running={running}
                        validateSerial={validateSerial}
                      />
                      {index + 1 - nbSerialNumbersInBdd > 0 && (
                        <Typography variant="caption">
                          {(index + 1 - nbSerialNumbersInBdd).toString()}
                        </Typography>
                      )}
                    </Box>
                  ))}
                {arSuivistock === SUIVI_STOCK_TYPE_LOT && (
                  <>
                    {[
                      // @ts-ignore
                      ...Array(serialNumbers.length).keys(),
                    ].map((index) => {
                      return (
                        <Box
                          key={index}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant="caption">{index + 1}</Typography>
                          <EntreeStockFArticleLotComponent
                            removeSerialNumber={removeSerialNumber}
                            updateSerialNumberQuantity={
                              updateSerialNumberQuantity
                            }
                            serialNumbers={serialNumbers}
                            maxCanAdd={maxCanAdd}
                            indexSerialNumber={index}
                            running={running}
                            ref={serialNumbers[index]?.ref}
                            getAllSerialNumbers={getValue}
                            validateSerial={validateSerial}
                          />
                        </Box>
                      );
                    })}
                    <Box sx={{ textAlign: "center" }}>
                      {maxCanAdd > 0 && (
                        <AddIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => addSerialLot()}
                        />
                      )}
                    </Box>
                  </>
                )}
              </>
            )}
        </>
      );
    }
  )
);

const EntreeStockFArticleComponent = React.memo(
  React.forwardRef(
    (
      {
        fDocenteteLigneArrivage,
        focusMainSearch,
        fDoclignes,
        index,
        running,
        setArrivage,
        focusSerialNumberIndex,
      }: State,
      ref
    ) => {
      const quantityRef: any = useRef();
      const formRefSerialNumbers: any = useRef();
      const { t } = useTranslation();

      const focusQuantity = React.useCallback(() => {
        quantityRef.current.focus();
        setTimeout(() => {
          quantityRef.current.select();
        });
      }, []);
      const theme = useTheme();

      const getQuantityNeeded = React.useCallback((): number => {
        return fDoclignes.map((f) => f.dlQte).reduce((a, b) => a + b, 0);
      }, [fDoclignes]);
      const getFArtstock = React.useCallback(():
        | FArtstockInterface
        | undefined => {
        return fDocenteteLigneArrivage?.fArtstocks?.find(
          (f) => f.fDepot.deNo === 1
        );
      }, [fDocenteteLigneArrivage?.fArtstocks]);
      const [fArtstock, setFArtstock] = React.useState<
        FArtstockInterface | undefined
      >(getFArtstock());
      const getTotalFound = React.useCallback((): number => {
        return fDoclignes.map((f) => f.found ?? 0).reduce((a, b) => a + b, 0);
      }, [fDoclignes]);
      const [quantityNeeded, setQuantityNeeded] = React.useState<number>(
        getQuantityNeeded()
      );
      const [totalFound, setTotalFound] = React.useState<number>(
        getTotalFound()
      );

      const getValues = React.useCallback((): FormState => {
        return {
          quantity: { value: fDocenteteLigneArrivage.dlQte, error: "" },
        };
      }, [fDocenteteLigneArrivage.dlQte]);
      const [values, setValues] = React.useState<FormState>(getValues());

      const handleChangeQuantity = React.useCallback(
        (addRemove: number) => {
          if (running) {
            return;
          }
          setValues((v) => {
            v.quantity.value = Number(v.quantity.value) + addRemove;
            return { ...v };
          });
          focusQuantity();
        },
        [focusQuantity, running]
      );

      const onQuantityChanged = React.useCallback(
        (force: boolean = false) => {
          if (
            values.quantity.value > 0 &&
            [SUIVI_STOCK_TYPE_SERIE, SUIVI_STOCK_TYPE_LOT].includes(
              fDocenteteLigneArrivage.arSuivistock
            )
          ) {
            setArrivage((a: ArrivageInterface) => {
              const result: ArrivageInterface = clone(a);
              const fDocenteteLigneArrivage: FDocenteteLigneArrivageInterface =
                result.data.fDocenteteLigneArrivages![index];
              fDocenteteLigneArrivage.dlQte = values.quantity.value;
              let newSerialNumbers: FDocenteteLigneArrivageSerialNumberInterface[] =
                [];
              let totalQuantity = 0;
              for (const serialNumber of fDocenteteLigneArrivage.serialNumbers!) {
                let quantity: number = Number(serialNumber.quantity);
                const newSerialNumber = {
                  ...serialNumber,
                  quantity: 0,
                };
                newSerialNumbers.push(newSerialNumber);
                while (quantity > 0) {
                  if (
                    newSerialNumber.dlNo ||
                    totalQuantity < fDocenteteLigneArrivage.dlQte
                  ) {
                    newSerialNumber.quantity++;
                    totalQuantity++;
                  }
                  quantity--;
                }
              }
              newSerialNumbers = newSerialNumbers.filter((s) => s.quantity > 0);
              fDocenteteLigneArrivage.serialNumbers = newSerialNumbers;
              if (
                fDocenteteLigneArrivage.arSuivistock === SUIVI_STOCK_TYPE_SERIE
              ) {
                for (
                  let i = fDocenteteLigneArrivage.serialNumbers?.length;
                  i < fDocenteteLigneArrivage.dlQte;
                  i++
                ) {
                  fDocenteteLigneArrivage.serialNumbers?.push({
                    dlNo: null,
                    serial: "",
                    quantity: 1,
                  });
                }
              }
              result.focusIndexAfterChangeQuantity = index;
              return result;
            });
          } else {
            focusMainSearch(undefined, force);
          }
        },
        [
          fDocenteteLigneArrivage.arSuivistock,
          focusMainSearch,
          index,
          setArrivage,
          values.quantity.value,
        ]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return {
            ...fDocenteteLigneArrivage,
            dlQte: values.quantity.value,
            serialNumbers: formRefSerialNumbers?.current?.getValue() ?? [],
          };
        },
      }));

      React.useEffect(() => {
        setValues(getValues());
      }, [fDocenteteLigneArrivage.dlQte]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setQuantityNeeded(getQuantityNeeded());
        setTotalFound(getTotalFound());
      }, [fDocenteteLigneArrivage.dlQte, fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setFArtstock(getFArtstock());
      }, [fDocenteteLigneArrivage?.fArtstocks?.length]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (focusSerialNumberIndex === index) {
          setArrivage((a: ArrivageInterface) => {
            const result: ArrivageInterface = clone(a);
            result.focusIndexAfterChangeQuantity = undefined;
            return result;
          });
          setTimeout(() => {
            formRefSerialNumbers.current.focusFirstSerial();
          });
        }
      }, [focusSerialNumberIndex]); // eslint-disable-line react-hooks/exhaustive-deps

      let dlQte = fDocenteteLigneArrivage.dlQte;
      if (
        fDocenteteLigneArrivage.serialNumbers &&
        (fDocenteteLigneArrivage.arSuivistock === SUIVI_STOCK_TYPE_SERIE ||
          fDocenteteLigneArrivage.arSuivistock === SUIVI_STOCK_TYPE_LOT)
      ) {
        dlQte = 0;
        for (const serialNumber of fDocenteteLigneArrivage.serialNumbers) {
          if (serialNumber.dlNo !== null || serialNumber.serial !== "") {
            dlQte += Number(serialNumber.quantity);
          }
        }
      }

      return (
        <Card
          variant="outlined"
          sx={{ flexShrink: 0, width: "100%" }}
          id={"card-" + fDocenteteLigneArrivage.arRef}
        >
          <CardContent sx={{ padding: "8px !important" }}>
            <Box>
              {fDocenteteLigneArrivage.image && (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    textAlign: "center",
                  }}
                  to={PRODUCT_PAGE + "/" + fDocenteteLigneArrivage.slug}
                >
                  <img
                    src={
                      (process.env.REACT_APP_API_URL ?? "") +
                      getUrlImageDirectory(fDocenteteLigneArrivage.arRef) +
                      "/" +
                      fDocenteteLigneArrivage.image
                    }
                    style={{
                      maxWidth: "73px",
                      float: "left",
                    }}
                    alt={fDocenteteLigneArrivage.arRef}
                    loading="lazy"
                  />
                </Link>
              )}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <CopyClipboardComponent
                  className="RobotoMono"
                  component="span"
                  text={fDocenteteLigneArrivage.arRef}
                  sx={{
                    color: theme.palette.success.main,
                  }}
                  afterCopy={() => {
                    focusMainSearch();
                    scrollToArticleFDocligne(
                      fDocenteteLigneArrivage.arRef,
                      fDoclignes
                    );
                  }}
                />
                <Box>
                  {t("word.stockReel") + ": "}
                  {fArtstock && Number(fArtstock.asQtesto)}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <FArticleRefConstructeurComponent
                    label=""
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    arRef={fDocenteteLigneArrivage.arRef}
                    constructeurRef={fDocenteteLigneArrivage.constructeurRef}
                    setFArticle={(fArticle: FArticleInterface) => {
                      setArrivage((a: ArrivageInterface) => {
                        const f = a.data.fDocenteteLigneArrivages?.find(
                          (y) => y.arRef === fArticle.arRef
                        );
                        if (f) {
                          f.constructeurRef = fArticle.constructeurRef;
                          return clone(a);
                        }
                        return a;
                      });
                    }}
                    edit={false}
                  />
                </Box>
                <UpdateEmplacementComponent
                  emplacement={fDocenteteLigneArrivage.emplacement}
                  disabled={running}
                  arRef={fDocenteteLigneArrivage.arRef}
                  fArtstockCbmarq={
                    fDocenteteLigneArrivage.fArtstocks?.find(
                      (f) => f.fDepot.deNo === 1
                    )?.cbmarq
                  }
                  setArrivage={setArrivage}
                  focusMainSearch={focusMainSearch}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <FArticleArCodebarreComponent
                    label=""
                    arCodebarre={fDocenteteLigneArrivage.arCodebarre}
                    arRef={fDocenteteLigneArrivage.arRef}
                    setFArticle={(fArticle: FArticleInterface) => {
                      setArrivage((a: ArrivageInterface) => {
                        const f = a.data.fDocenteteLigneArrivages?.find(
                          (y) => y.arRef === fArticle.arRef
                        );
                        if (f) {
                          f.arCodebarre = fArticle.arCodebarre;
                          return clone(a);
                        }
                        return a;
                      });
                    }}
                    edit={false}
                  />
                </Box>
                <UpdateColisageComponent
                  arRef={fDocenteteLigneArrivage.arRef}
                  fArtfourniss={fDocenteteLigneArrivage.fArtfourniss}
                  disabled={running}
                  setArrivage={setArrivage}
                  fDoclignes={fDoclignes}
                  focusMainSearch={focusMainSearch}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined" required>
                  <OutlinedInput
                    type="number"
                    inputRef={quantityRef}
                    disabled={running}
                    onFocus={(e) => e.target.select()}
                    value={values.quantity.value}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        onQuantityChanged();
                      }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let value = event.target.value;
                      setValues((v) => {
                        v.quantity.value = value !== "" ? Number(value) : value;
                        return { ...v };
                      });
                    }}
                    inputProps={{
                      className: "updateArrivage",
                      sx: {
                        width: 27,
                        textAlign: "center",
                        padding: 0,
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <AddIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleChangeQuantity(1);
                          }}
                        />
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <RemoveIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleChangeQuantity(-1);
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {fDoclignes[0] !== undefined ? (
                  <UpdateSuiviStockComponent
                    arRef={fDocenteteLigneArrivage.arRef}
                    arSuivistock={fDocenteteLigneArrivage.arSuivistock}
                    disabled={running}
                  />
                ) : (
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={t(
                      "word.arSuivistock." +
                        fDocenteteLigneArrivage.arSuivistock
                    )}
                  />
                )}

                <UpdateWeightComponent
                  arRef={fDocenteteLigneArrivage.arRef}
                  arPoidsnet={fDocenteteLigneArrivage.arPoidsnet}
                  disabled={running}
                  setArrivage={setArrivage}
                  focusMainSearch={focusMainSearch}
                  color="inherit"
                />
              </Box>
              <Typography component="span" sx={{ fontSize: "0.8rem" }}>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    textAlign: "center",
                  }}
                  to={PRODUCT_PAGE + "/" + fDocenteteLigneArrivage.slug}
                >
                  {fDocenteteLigneArrivage.arDesign}
                </Link>
              </Typography>
              {fDocenteteLigneArrivage.found !== undefined && (
                <>
                  <Chip
                    label={dlQte + "/" + quantityNeeded}
                    size="small"
                    variant="outlined"
                    color={dlQte === quantityNeeded ? "success" : "error"}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (
                        Number(quantityNeeded) !== Number(values.quantity.value)
                      ) {
                        setValues((v) => {
                          v.quantity.value = Number(quantityNeeded);
                          return { ...v };
                        });
                        setTimeout(() => {
                          onQuantityChanged(true);
                        });
                      }
                    }}
                  />
                  {dlQte < quantityNeeded && (
                    <Chip
                      label={quantityNeeded - dlQte}
                      size="small"
                      variant="outlined"
                      color={"error"}
                      sx={{ marginLeft: 1 }}
                    />
                  )}
                </>
              )}
            </Box>
            <Box>
              <EntreeStockFArticleSerieLotComponent
                fDoclignesQuantity={totalFound}
                fDocenteteLigneArrivage={fDocenteteLigneArrivage}
                arSuivistock={fDocenteteLigneArrivage.arSuivistock}
                ref={formRefSerialNumbers}
                running={running}
                focusMainSearch={focusMainSearch}
              />
            </Box>
          </CardContent>
        </Card>
      );
    }
  )
);

export default EntreeStockFArticleComponent;
