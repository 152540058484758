import * as React from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

interface State {
  text: string;
  hoverText?: string;
  icon?: React.ReactNode;
  className?: string;
  component?: any;
  sx?: SxProps<Theme>;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  afterCopy?: Function;
}

const CopyClipboardComponent: React.FC<State> = React.memo(
  ({
    text,
    hoverText,
    className,
    component,
    sx,
    placement,
    afterCopy,
    icon,
  }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [displayTooltip, setDisplayTooltip] = React.useState(
      hoverText ?? t("word.copied")
    );
    const copy = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(text);
        setOpen(true);
        setDisplayTooltip(t("word.copied"));
        setTimeout(() => {
          setOpen(false);
          if (hoverText) {
            setDisplayTooltip(hoverText);
          }
        }, 500);
        if (afterCopy) {
          afterCopy();
        }
      },
      [afterCopy, hoverText, t, text]
    );

    return (
      <>
        {text && (
          <Tooltip
            title={displayTooltip}
            open={open}
            placement={placement}
            onOpen={() => {
              if (!hoverText) {
                return;
              }
              setOpen(true);
            }}
            onClose={() => {
              if (!hoverText) {
                return;
              }
              setOpen(false);
            }}
          >
            <Typography
              className={className}
              component={component}
              onClick={copy}
              sx={{
                cursor: "pointer",
                borderStyle: "dashed",
                borderColor: "gray",
                borderWidth: "thin",
                wordBreak: "break-word",
                ...sx,
              }}
            >
              {icon ? icon : text}
            </Typography>
          </Tooltip>
        )}
      </>
    );
  }
);

export default CopyClipboardComponent;
