import * as React from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { MANUAL_CRON_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import IconButton from "@mui/material/IconButton";
import CircleIcon from "@mui/icons-material/Circle";
import { Container, Tooltip } from "@mui/material";
import {
  STATUS_FAILED,
  STATUS_RUNNING,
  STATUS_SUCCESS,
  STATUS_WAITING,
} from "../../../utils/ManualCronUtils";
import DrawerComponent from "../DrawerComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import DialogCronComponent from "./DialogCronComponent";
import { differenceInDays } from "date-fns";
import ContentComponent from "../../content/ContentComponent";
import { ManualCronInterface } from "../../../interfaces/TaskInterface";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface DisplayManualCron {
  color: any;
  text: string;
}

const minWidth = 900;

const CronComponent: React.FC = React.memo(() => {
  const crons = useAppSelector((state: RootState) => state.globalState.crons);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<string | undefined>(undefined);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const width = window.innerWidth;
  const [manualCron, setManualCron] = React.useState<
    ManualCronInterface | undefined
  >(undefined);

  const handleDrawerClose = React.useCallback(() => {
    setManualCron(undefined);
  }, []);

  const handleDrawerOpen = React.useCallback(
    (thisManualCron: ManualCronInterface) => {
      setManualCron(thisManualCron);
    },
    []
  );

  const getDisplay = React.useCallback(
    (cronManuel: null | ManualCronInterface): DisplayManualCron => {
      const result = {
        color: "default",
        text: "word.manualCron.never",
      };
      if (cronManuel) {
        result.text = "word.manualCron." + cronManuel.status;
        if (cronManuel.status === STATUS_WAITING) {
          result.color = "warning";
        } else if (cronManuel.status === STATUS_RUNNING) {
          result.color = "info";
        } else if (cronManuel.status === STATUS_SUCCESS) {
          result.color = "success";
        } else if (cronManuel.status === STATUS_FAILED) {
          result.color = "error";
        }
      }
      return result;
    },
    []
  );

  const launch = React.useCallback(
    async (name: string) => {
      setLoading(name);
      const response = await requestApi({
        method: POST,
        path: MANUAL_CRON_URL,
        allowError: true,
        token: token,
        body: {
          name: name,
        },
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.manual_cron_created")
        );
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(undefined);
    },
    [t, token]
  );

  return (
    <>
      <DrawerComponent
        open={!!manualCron}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={<DialogCronComponent cron={manualCron} />}
      />
      <Container maxWidth="xl" sx={{ marginY: 2 }}>
        <ContentComponent contentName="cron" saveLocalStorage={true} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("word.name")}</StyledTableCell>
                <StyledTableCell>{t("word.frequency")}</StyledTableCell>
                <StyledTableCell>{t("word.lastExecution")}</StyledTableCell>
                <StyledTableCell>{t("word.status")}</StyledTableCell>
                <StyledTableCell>{t("word.description")}</StyledTableCell>
                <StyledTableCell>{t("column.actions")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {crons &&
                crons.map((cron, indexCron) => {
                  const cronDisplay = getDisplay(cron.previous);
                  let diffDay: null | number = null;
                  let diffDayLabel: string = "";
                  if (cron.previous && cron.previous.ended !== null) {
                    diffDay = differenceInDays(
                      new Date(),
                      // @ts-ignore
                      new Date(cron.previous.ended)
                    );
                    if (diffDay === 0) {
                      diffDayLabel = "word.today";
                    }
                    if (diffDay === 1) {
                      diffDayLabel = "word.day";
                    }
                    if (diffDay > 1) {
                      diffDayLabel = "word.days";
                    }
                  }
                  return (
                    <StyledTableRow key={indexCron}>
                      <StyledTableCell>{cron.name}</StyledTableCell>
                      <StyledTableCell>{cron.expression}</StyledTableCell>
                      <StyledTableCell>
                        {diffDay !== null && diffDay > 0 && (
                          <span>{diffDay}</span>
                        )}
                        {" " + t(diffDayLabel)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title={t(cronDisplay.text)}>
                          <IconButton size="large" color={cronDisplay.color}>
                            <CircleIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ol style={{ paddingLeft: 16 }}>
                          {cron.description
                            .split("|")
                            .map(
                              (
                                description: string,
                                indexDescription: number
                              ) => (
                                <li key={indexDescription}>{description}</li>
                              )
                            )}
                        </ol>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ display: "flex" }}>
                          {cron.runnable && (
                            <LoadingButton
                              variant="text"
                              color="inherit"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "auto",
                                padding: "12px",
                              }}
                              loading={loading === cron.name}
                              onClick={() => launch(cron.name)}
                            >
                              <RocketLaunchIcon />
                            </LoadingButton>
                          )}
                          {cron.previous && (
                            <LoadingButton
                              variant="text"
                              color="inherit"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "auto",
                                padding: "12px",
                              }}
                              // @ts-ignore
                              onClick={() => handleDrawerOpen(cron.previous)}
                            >
                              <VisibilityIcon />
                            </LoadingButton>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
});

export default CronComponent;
