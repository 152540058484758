import * as React from "react";
import { useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { Grid, Skeleton, TextField, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";

interface State {
  fArticle: FArticleInterface | undefined;
  createFrom?: FArticleInterface;
}

interface FormState {
  newArRef: InputInterface;
}

const FArticleArRefComponent = React.memo(
  React.forwardRef(({ fArticle, createFrom }: State, ref) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        newArRef: {
          value: createFrom?.arRef ?? "",
          error: "",
        },
      };
    }, [createFrom?.arRef]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [estimatedArRef, setEstimatedArRef] = React.useState(null);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setEstimatedArRef(null);
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const searchFunction = React.useCallback(async () => {
      const response = await requestApi({
        method: GET,
        path:
          FARTICLE_URL +
          "/find/ar-ref" +
          objectToQuery({
            newArRef: values.newArRef.value,
          }),
        allowError: true,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        setEstimatedArRef(response.content.arRef);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [values.newArRef.value, token, t]);

    const getValue = React.useCallback(() => {
      const arRefError = notEmptyValidator(values.newArRef.value);
      if (arRefError) {
        const newValue: FormState = { ...values };
        if (arRefError) {
          newValue.newArRef.error = arRefError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        arRef: values.newArRef.value,
      };
    }, [values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      if (createFrom) {
        setValues(getDefaultValues());
      }
    }, [createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFunction();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [values.newArRef.value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {createFrom ? (
          <>
            <Grid container spacing={1} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.newArRef.error}
                  helperText={t(values.newArRef.error ?? "")}
                  sx={{ width: "100%", marginTop: 1 }}
                  type="text"
                  value={values.newArRef.value}
                  onChange={handleChange("newArRef")}
                  label={t("word.refNove")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {estimatedArRef ? (
                  <Typography sx={{ color: theme.palette.success.main }}>
                    {t("word.estimatedArRef") + ": " + estimatedArRef}
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography sx={{ width: "100%" }}>
            <span style={{ color: grey[500] }}>{t("word.refNove") + ": "}</span>
            {fArticle?.fArticleProp === undefined ? (
              <Skeleton sx={{ flex: 1 }} variant="text" />
            ) : (
              <CopyClipboardComponent
                className="RobotoMono"
                component="span"
                sx={{
                  color: theme.palette.success.main,
                }}
                text={fArticle?.arRef}
              />
            )}
          </Typography>
        )}
      </>
    );
  })
);

export default FArticleArRefComponent;
