import React, { useImperativeHandle, useRef } from "react";
import SearchEntreeStockComponent from "./SearchEntreeStockComponent";
import {
  ArrivageFDocligneInterface,
  ArrivageInterface,
  FDocenteteLigneArrivageInterface,
  FDocenteteLigneArrivageSerialNumberInterface,
  IgnoredDocumentInterface,
  UpdatingArrivageInterface,
} from "../../../interfaces/ArrivageInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  ARRIVAGE_URL,
  FARTICLE_URL,
  FDOCENTETES_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import {
  Chip,
  CircularProgress,
  DialogProps,
  Skeleton,
  Tooltip,
} from "@mui/material";
import NotFoundComponent from "../NotFoundComponent";
import { set } from "../../../app/globalSlice";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  FArticleInterface,
  FArtstockInterface,
} from "../../../interfaces/FArticleInterface";
import FArticleNotSureArrivageComponent from "./FArticleNotSureArrivageComponent";
import Divider from "@mui/material/Divider"; // https://stackoverflow.com/a/71046070/6824121
import errorSound from "../../../assets/error.mp3";
import {
  getUrlFArticleImage,
  getUrlImageDirectory,
} from "../../../helpers/FileHelper";
import { IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import validSound from "../../../assets/valid.mp3";
import tadaSound from "../../../assets/tada.mp3";
import getErrorApi from "../../../helpers/GetErrorApi";
import EntreeStockFArticleComponent from "./EntreeStockFArticleComponent";
import {
  SUIVI_STOCK_TYPE_LOT,
  SUIVI_STOCK_TYPE_SERIE,
} from "../../../utils/FDoceneteteUtils";
import EntreeStockIgnoredDocumentsComponent from "./EntreeStockIgnoredDocumentsComponent";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogActions from "@mui/material/DialogActions";
import ArrivageActionFormComponent from "./form/ArrivageActionFormComponent";
import {
  addStateFDoclignes,
  createTransformFDocentetes,
  mapSerialNumbers,
} from "../../../helpers/FDocenteteHelper";
import { getDiff } from "json-difference";
import { green, red, yellow } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  STORAGE_FAST_TRANSFORM_ARRIVAGE,
  STORAGE_HIDE_FDOCLIGNES,
} from "../../../utils/StorageUtils";
import InfoIcon from "@mui/icons-material/Info";
import buzzSound from "../../../assets/buzz.mp3";
import EntreeStockDisplayFDocligneComponent from "./display/EntreeStockDisplayFDocligneComponent";
import { TransformFDocenteteArrivageInterface } from "../../../interfaces/FDocenteteInterface";

const clone = require("clone");

interface State {
  arrivageId: string | undefined;
  mainRef: any;
}

export interface StateFDocligne {
  currentBonDeCommande: string;
  currentBonDeLivraison: string;
  currentFacture: string;
  nextBonDeCommande: string;
  nextBonDeLivraison: string;
  nextFacture: string;
  previousBonDeCommande: string;
  previousBonDeLivraison: string;
  previousFacture: string;
  transformTo: string;
}

interface State6 {
  running: boolean;
  setTransform: Function;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  fDoclignes: ArrivageFDocligneInterface[];
  doType: number;
  open: boolean;
  setOpen: Function;
  fDocenteteLigneArrivages: FDocenteteLigneArrivageInterface[];
  updatingArrivage: UpdatingArrivageInterface;
}

export interface StepActionTransformInterface {
  dlNo: string;
  oldDoType: number;
  oldDoPiece: string;
  doType: number;
  doPiece: string;
  quantity: number;
  newDoPiece?: string;
  serialNumbers?: FDocenteteLigneArrivageSerialNumberInterface[];
  started: boolean;
  success: boolean | undefined;
}

export interface ActionTransformInterface {
  dlNo: string;
  steps: StepActionTransformInterface[];
}

export interface TransformInterface {
  running: boolean;
  actions: ActionTransformInterface[];
}

export interface FDocenteteFoundInterface {
  doType: number;
  doPiece: string;
  doTiers: string;
}

let arrivageUpdateTime = Date.now();
let prevArrivageUpdateTime = Date.now();

export const scrollToArticleFDocligne = (
  arRef: string,
  fDoclignes: ArrivageFDocligneInterface[],
  lightBackground: boolean = true
) => {
  Array.from(document.querySelectorAll(".light-background")).forEach((el) =>
    el.classList.remove("light-background")
  );
  // region card
  const cardDom = document.querySelector("#card-" + arRef);
  if (cardDom) {
    cardDom.scrollIntoView({
      inline: "start",
      block: "center",
    });
    let i = 0;
    const interval = setInterval(() => {
      cardDom.classList.toggle("light-background");
      if (i === 2) {
        clearInterval(interval);
        if (lightBackground) {
          cardDom.classList.add("light-background");
        } else {
          cardDom.classList.remove("light-background");
        }
      }
      i++;
    }, 200);
  }
  // endregion
  // region fDocligne
  if (fDoclignes.length === 0) {
    return;
  }
  const trDoms = document.querySelectorAll(".fDocligne-arRef-" + arRef);
  for (const [l, trDom] of Object.entries(trDoms)) {
    if (trDom) {
      if (Number(l) === trDoms.length - 1) {
        trDom.scrollIntoView({
          inline: "start",
          block: "center",
        });
      }
      let i = 0;
      const interval = setInterval(() => {
        trDom.classList.toggle("light-background");
        if (i === 2) {
          clearInterval(interval);
          if (lightBackground) {
            trDom.classList.add("light-background");
          } else {
            trDom.classList.remove("light-background");
          }
        }
        i++;
      }, 200);
    }
  }
  // endregion
};

export const FormTransform = React.memo(
  ({
    running,
    setTransform,
    fDoclignes,
    fDocenteteFounds,
    setFDocenteteFounds,
    doType,
    open,
    setOpen,
    fDocenteteLigneArrivages,
    updatingArrivage,
  }: State6) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const formRef: any = useRef();

    const handleClose = React.useCallback(() => {
      setOpen(false);
    }, [setOpen]);

    const createAction = React.useCallback(() => {
      if (running) {
        toastr.info(t("word.info"), t("error.waitTransformFinish"));
        handleClose();
        return;
      }
      const values = formRef.current.getValue();
      if (!values) {
        return;
      }
      handleClose();
      setTransform((transform: TransformInterface) => {
        transform.running = true;
        transform.actions = [];
        for (const value of values) {
          if (
            transform.actions.find((a) => a.dlNo === value.dlNo) === undefined
          ) {
            transform.actions.push({
              dlNo: value.dlNo,
              steps: value.values.map((v: any) => {
                return {
                  ...v,
                  dlNo: value.dlNo,
                  started: false,
                  success: undefined,
                };
              }),
            });
          }
        }
        return clone(transform);
      });
    }, [handleClose, running, setTransform, t]);

    return (
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={handleClose}
        open={
          open && !updatingArrivage.currentUpdateTime && !updatingArrivage.error
        }
      >
        <DialogContent>
          <ArrivageActionFormComponent
            ref={formRef}
            fDoclignes={mapSerialNumbers(fDoclignes, fDocenteteLigneArrivages)}
            doType={doType}
            fDocenteteFounds={fDocenteteFounds}
            setFDocenteteFounds={setFDocenteteFounds}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton onClick={handleClose}>
            {t("word.cancel")}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={createAction}>
            {t("word.transform")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

let lastAddedArRef: string | null = null;

const EntreeStockArrivageComponent = React.memo(
  React.forwardRef(({ arrivageId, mainRef }: State, ref) => {
    const [arrivage, setArrivage] = React.useState<
      ArrivageInterface | undefined | null
    >(undefined);
    const getFastTransform = React.useCallback((): boolean => {
      return localStorage.getItem(STORAGE_FAST_TRANSFORM_ARRIVAGE) !== "0";
    }, []);
    const getHideFDoclignes = React.useCallback((): boolean => {
      return localStorage.getItem(STORAGE_HIDE_FDOCLIGNES) === "1";
    }, []);
    const handleFastTransform = React.useCallback(() => {
      return setFastTransform((x) => {
        localStorage.setItem(STORAGE_FAST_TRANSFORM_ARRIVAGE, !x ? "1" : "0");
        return !x;
      });
    }, []);
    const handleHideFDoclignes = React.useCallback(() => {
      return setHideFDoclignes((x) => {
        localStorage.setItem(STORAGE_HIDE_FDOCLIGNES, !x ? "1" : "0");
        return !x;
      });
    }, []);
    const [fastTransform, setFastTransform] = React.useState<boolean>(
      getFastTransform()
    );
    const [hideFDoclignes, setHideFDoclignes] = React.useState<boolean>(
      getHideFDoclignes()
    );
    const [updatingArrivage, setUpdatingArrivage] =
      React.useState<UpdatingArrivageInterface>({
        currentUpdateTime: undefined,
        error: false,
      });
    const { t } = useTranslation();
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const cacheName = useAppSelector(
      (state: RootState) => state.globalState.cacheName
    );

    const dispatch = useAppDispatch();
    const [fArticleNotSures, setFArticleNotSures] = React.useState<
      FArticleInterface[] | undefined
    >(undefined);
    const [transform, setTransform] = React.useState<TransformInterface>({
      actions: [],
      running: false,
    });
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [errorAudio] = React.useState<HTMLAudioElement>(
      new Audio(errorSound)
    );
    const [buzzAudio] = React.useState<HTMLAudioElement>(new Audio(buzzSound));
    const [validAudio] = React.useState<HTMLAudioElement>(
      new Audio(validSound)
    );
    const [tadaAudio] = React.useState<HTMLAudioElement>(new Audio(tadaSound));
    const [currentFocus, setCurrentFocus] = React.useState<
      string | undefined | null
    >(undefined);
    const [lastFocusElement, setLastFocusElement] =
      React.useState<Element | null>(null);
    const [searchingFArticle, setSearchingFArticle] =
      React.useState<boolean>(false);
    const getFArtStock = React.useCallback(
      (
        fArticle: FArticleInterface | undefined
      ): FArtstockInterface | undefined => {
        if (fArticle && fArticle.fArtstocks && fArticle.fArtstocks.length > 0) {
          return fArticle.fArtstocks.find((x) => x.fDepot.deNo === 1);
        }
        return undefined;
      },
      []
    );
    const headerRef: any = useRef();
    const contentRef: any = useRef();
    const fDoclignesRef: any = useRef();
    const [heightComponent, setHeightComponent] = React.useState<
      number | undefined
    >(undefined);

    const updateArrivage = React.useCallback(async () => {
      if (!arrivage?.data || transform.running) {
        return;
      }
      const newFDocenteteLigneArrivages =
        arrivage.data.fDocenteteLigneArrivages?.map((f) => {
          return f.ref.current.getValue();
        }) ?? [];
      const diff = getDiff(
        arrivage.data.fDocenteteLigneArrivages ?? [],
        newFDocenteteLigneArrivages
      );
      if (
        diff.added.length === 0 &&
        diff.edited.length === 0 &&
        diff.removed.length === 0
      ) {
        return;
      }
      setArrivage((a) => {
        if (a?.data.fDocenteteLigneArrivages) {
          a.data.fDocenteteLigneArrivages = newFDocenteteLigneArrivages;
          arrivageUpdateTime = Date.now();
          a.updateTime = arrivageUpdateTime;
          return { ...a };
        }
        return a;
      });
    }, [arrivage?.data, transform.running]);

    const focusMainSearch = React.useCallback(
      (e: any = undefined, force: boolean = false) => {
        const tagName = e?.target?.tagName?.toLowerCase();
        if (force) {
          updateArrivage();
        }
        if (["input", "svg", "path"].includes(tagName)) {
          setLastFocusElement(document.activeElement);
          return;
        }
        if (
          !force &&
          lastFocusElement &&
          lastFocusElement.classList.contains("updateArrivage")
        ) {
          updateArrivage();
        }
        setCurrentFocus((x) => {
          if (x === undefined) {
            return null;
          }
          return undefined;
        });
        setLastFocusElement(document.activeElement);
      },
      [lastFocusElement, updateArrivage]
    );

    const load = React.useCallback(async () => {
      if (arrivageId === undefined) {
        setArrivage(null);
        dispatch(set({ refreshPage: false }));
        return;
      }
      const response = await requestApi({
        method: GET,
        path:
          ARRIVAGE_URL +
          "/" +
          arrivageId +
          objectToQuery({
            refresh: 1,
            cacheName: cacheName,
          }),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        const newArrivage: ArrivageInterface | undefined = addStateFDoclignes(
          response.content
        );
        if (newArrivage) {
          if (updatingArrivage.error) {
            setUpdatingArrivage((v) => {
              v.error = false;
              return { ...v };
            });
          }
          setArrivage((a) => {
            if (Array.isArray(newArrivage.data)) {
              // https://stackoverflow.com/q/78070223/6824121
              newArrivage.data = {};
            }
            return {
              ...newArrivage,
              updateTime: a?.updateTime,
            };
          });
        }
      } else {
        setArrivage(null);
        if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else if (response.statusCode === 404) {
          // nothing
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }
      setTimeout(() => {
        dispatch(set({ refreshPage: false }));
      });
    }, [arrivageId, cacheName, dispatch, t, token, updatingArrivage.error]);

    const getImage = React.useCallback(
      (fArticle: FArticleInterface): string | null => {
        let image;
        // @ts-ignore
        if (fArticle.imageFDocligne) {
          // @ts-ignore
          image = fArticle.imageFDocligne;
        } else {
          image =
            fArticle.fArticleProp?.images &&
            fArticle.fArticleProp?.images.length > 0
              ? getUrlFArticleImage(
                  fArticle?.arRef,
                  fArticle.fArticleProp?.images[0],
                  IMAGE_SIZE_SMALL
                )
              : null;
        }
        if (image) {
          const dir = getUrlImageDirectory(fArticle.arRef);
          if (dir) {
            image = image.replace(dir + "/", "");
          } else {
            image = null;
          }
        }
        return image;
      },
      []
    );

    const addFDocenteteLigneArrivage = React.useCallback(
      (fArticle: FArticleInterface) => {
        if (
          [SUIVI_STOCK_TYPE_SERIE, SUIVI_STOCK_TYPE_LOT].includes(
            fArticle.arSuivistock
          )
        ) {
          tadaAudio.play();
        } else {
          validAudio.play();
        }
        focusMainSearch();
        setFArticleNotSures(undefined);
        setArrivage((a) => {
          if (!a) {
            return a;
          }
          if (!a.data.fDocenteteLigneArrivages) {
            a.data.fDocenteteLigneArrivages = [];
          }
          const index = a.data.fDocenteteLigneArrivages.findIndex(
            (f) => f.arRef === fArticle.arRef
          );
          arrivageUpdateTime = Date.now();
          a.updateTime = arrivageUpdateTime;
          if (index >= 0) {
            a.data.fDocenteteLigneArrivages[index].dlQte++;
          } else {
            lastAddedArRef = fArticle.arRef;
            a.data.fDocenteteLigneArrivages.push({
              dlQte: 1,
              arRef: fArticle.arRef,
              constructeurRef: fArticle.constructeurRef,
              arCodebarre: fArticle.arCodebarre,
              arPoidsnet: fArticle.arPoidsnet,
              slug: fArticle.arRef,
              arDesign: fArticle.arDesign,
              arSuivistock: fArticle.arSuivistock,
              emplacement:
                getFArtStock(fArticle)?.cbdpNoprincipal?.dpCode ?? null,
              image: getImage(fArticle),
              fArtstocks: clone(fArticle.fArtstocks),
            });
          }
          return { ...a };
        });
        setTimeout(() => {
          scrollToArticleFDocligne(fArticle.arRef, arrivage?.fDoclignes ?? []);
        }, 200);
      },
      [
        arrivage?.fDoclignes,
        focusMainSearch,
        getFArtStock,
        getImage,
        tadaAudio,
        validAudio,
      ]
    );

    const onChange = React.useCallback(
      async (search: string) => {
        search = search.trim();
        if (search === "") {
          setFArticleNotSures(undefined);
          return;
        }
        if (arrivage?.data?.fDocenteteLigneArrivages) {
          const indexFArticle =
            arrivage.data.fDocenteteLigneArrivages.findIndex(
              (f) => f.arRef === search
            );
          if (indexFArticle >= 0) {
            validAudio.play();
            focusMainSearch();
            setArrivage((a) => {
              if (!a) {
                return a;
              }
              arrivageUpdateTime = Date.now();
              a.updateTime = arrivageUpdateTime;
              a.data!.fDocenteteLigneArrivages![indexFArticle].dlQte++;
              return { ...a };
            });
            return;
          }
        }
        if (arrivage?.fDoclignes) {
          const fDocligne = arrivage?.fDoclignes.find((f) => {
            return (
              f.arRef === search ||
              f.constructeurRef === search ||
              f.arCodebarre === search
            );
          });
          if (fDocligne) {
            const fArticle: any = {
              arRef: fDocligne.arRef,
              constructeurRef: fDocligne.constructeurRef,
              arPoidsnet: fDocligne.arPoidsnet,
              arCodebarre: fDocligne.arCodebarre,
              arDesign: fDocligne.arDesign,
              arSuivistock: fDocligne.arSuivistock,
              imageFDocligne: fDocligne.image,
            };
            addFDocenteteLigneArrivage(fArticle);
            requestApi({
              method: GET,
              path:
                FARTICLE_URL +
                objectToQuery({
                  keywords: fDocligne.arRef,
                  itemsPerPage: 1,
                  full: true,
                }),
              allowError: true,
              token: token,
            }).then((response) => {
              if (response.statusCode === 200) {
                setArrivage((a) => {
                  const fArticleR = response.content.fArticles.find(
                    (f: FArticleInterface) => f.arRef === fDocligne.arRef
                  );
                  if (a?.data.fDocenteteLigneArrivages && fArticle) {
                    const fDocenteteLigneArrivage =
                      a.data.fDocenteteLigneArrivages.find(
                        (f) => f?.arRef === fArticleR?.arRef
                      );
                    if (fDocenteteLigneArrivage) {
                      fDocenteteLigneArrivage.constructeurRef =
                        fArticleR.constructeurRef;
                      fDocenteteLigneArrivage.arCodebarre =
                        fArticleR.arCodebarre;
                      fDocenteteLigneArrivage.arPoidsnet = fArticleR.arPoidsnet;
                      fDocenteteLigneArrivage.arDesign = fArticleR.arDesign;
                      fDocenteteLigneArrivage.fArtstocks = fArticleR.fArtstocks;
                      fDocenteteLigneArrivage.image = getImage(fArticleR);
                      fDocenteteLigneArrivage.arSuivistock =
                        fArticleR.arSuivistock;
                      fDocenteteLigneArrivage.emplacement =
                        getFArtStock(fArticleR)?.cbdpNoprincipal?.dpCode ??
                        null;
                      // double request on first search fArticle
                      // arrivageUpdateTime = Date.now();
                      a.updateTime = arrivageUpdateTime;
                      return { ...a };
                    }
                  }
                  return a;
                });
              }
            });
            return;
          }
        }
        setSearchingFArticle(true);
        const response = await requestApi({
          method: GET,
          path:
            FARTICLE_URL +
            objectToQuery({
              keywords: search,
              itemsPerPage: 6,
              full: true,
            }),
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          if (response.content.fArticles.length === 1) {
            addFDocenteteLigneArrivage(response.content.fArticles[0]);
          } else {
            errorAudio.play();
            toastr.error(
              t("word.error"),
              t("error.arrivage.notFoundProduct") + " [" + search + "]",
              {
                timeOut: 0,
              }
            );
            if (response.content.fArticles.length > 0) {
              setFArticleNotSures(response.content.fArticles);
            }
          }
        } else {
          errorAudio.play();
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"), {
              timeOut: 0,
            });
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"), {
              timeOut: 0,
            });
          }
        }
        setSearchingFArticle(false);
      },
      [
        addFDocenteteLigneArrivage,
        arrivage?.data?.fDocenteteLigneArrivages,
        arrivage?.fDoclignes,
        errorAudio,
        focusMainSearch,
        getFArtStock,
        getImage,
        t,
        token,
        validAudio,
      ]
    );

    const updateIgnoredDocument = React.useCallback(
      (ignoredDocuments: IgnoredDocumentInterface[]) => {
        if (!arrivage?.id) {
          return;
        }
        setArrivage((a) => {
          if (a?.ignoredDocuments) {
            a.ignoredDocuments = ignoredDocuments;
            arrivageUpdateTime = Date.now();
            a.updateTime = arrivageUpdateTime;
            return { ...a };
          }
          return a;
        });
      },
      [arrivage?.id]
    );

    const updateActions = React.useCallback(async () => {
      if (!transform.running || !arrivageId) {
        return;
      }
      // region transform all at once
      const body: TransformFDocenteteArrivageInterface = {
        arrivageId: Number(arrivageId),
        transformFDocentetes: createTransformFDocentetes(transform),
      };
      const response = await requestApi({
        method: POST,
        path:
          FDOCENTETES_URL +
          "/transform/f_doclignes" +
          objectToQuery({
            refresh: 1,
            cacheName: cacheName,
          }),
        allowError: true,
        token: token,
        timeout: 300_000, // 5min
        body: body,
      });
      if (response.statusCode === 200) {
        setArrivage((a) => {
          if (!a) {
            return a;
          }
          // @ts-ignore
          const newArrivage: ArrivageInterface = {
            ...addStateFDoclignes(response.content),
            updateTime: a.updateTime,
          };
          fDoclignesRef.current.updateArrivage(newArrivage);
          return newArrivage;
        });
        setTransform({
          actions: [],
          running: false,
        });
      } else {
        if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"), {
            timeOut: 0,
          });
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message), {
              timeOut: 0,
            });
          }
        }
      }
      // endregion
      // region transform line by line
      // const doPieces = [
      //   // @ts-ignore
      //   ...new Set(arrivage?.fDoclignes?.map((f) => f.doPiece) ?? []),
      // ];
      // for (const doTypeTransforming of [
      //   SAGE_DO_TYPE_ACHAT_LI,
      //   SAGE_DO_TYPE_ACHAT_FA,
      // ]) {
      //   for (const indexAction in transform.actions) {
      //     const action: ActionTransformInterface =
      //       transform.actions[indexAction];
      //     for (const indexStep in action.steps) {
      //       const step: StepActionTransformInterface = action.steps[indexStep];
      //       if (step.doType !== doTypeTransforming) {
      //         continue;
      //       }
      //       if (!step.started && step.success === undefined) {
      //         setTransform((thisTransform) => {
      //           thisTransform.actions[indexAction].steps[indexStep].started =
      //             true;
      //           return clone(thisTransform);
      //         });
      //         return;
      //       }
      //       let newDoPiece = step.doPiece;
      //       const newDoType = step.doType;
      //       if (fastTransform) {
      //         newDoPiece = getStepDoPieceFastTransform(
      //           step,
      //           arrivage?.fDoclignes ?? [],
      //           newDoType
      //         );
      //       }
      //       if (step.started && step.success === undefined) {
      //         const response = await requestApi({
      //           method: PATCH,
      //           path: FDOCLIGNE_URL + "/" + step.dlNo,
      //           allowError: true,
      //           token: token,
      //           body: {
      //             arrivageId: Number(arrivageId),
      //             fDocentetesArrivage: doPieces,
      //             newDoPiece: newDoPiece,
      //             newDoType: step.doType,
      //             newQuantity: step.quantity,
      //             serialNumbers: step.serialNumbers?.map((s) => {
      //               return {
      //                 serial: s.serial,
      //                 quantity: s.quantity,
      //               };
      //             }),
      //           },
      //         });
      //         if (response.statusCode === 200) {
      //           // @ts-ignore
      //           setArrivage((a) => {
      //             if (!a) {
      //               return a;
      //             }
      //             const newArrivage = {
      //               ...addStateFDoclignes(response.content.arrivage),
      //               updateTime: a.updateTime,
      //             };
      //             fDoclignesRef.current.updateArrivage(newArrivage);
      //             return newArrivage;
      //           });
      //           setTransform((thisTransform) => {
      //             thisTransform = clone(thisTransform);
      //             if (thisTransform?.actions[indexAction]?.steps[indexStep]) {
      //               thisTransform.actions[indexAction].steps[
      //                 indexStep
      //               ].success = true;
      //               const nextIndexStep: number = Number(indexStep) + 1;
      //               if (
      //                 thisTransform.actions[indexAction].steps[indexStep]
      //                   .doType === SAGE_DO_TYPE_ACHAT_LI &&
      //                 thisTransform.actions[indexAction].steps[
      //                   nextIndexStep
      //                 ] !== undefined
      //               ) {
      //                 const newStep = clone(
      //                   thisTransform.actions[indexAction].steps[nextIndexStep]
      //                 );
      //                 thisTransform.actions[indexAction].steps.splice(
      //                   nextIndexStep,
      //                   1
      //                 );
      //                 for (const newDlNo of response.content.newDlNo) {
      //                   thisTransform.actions[indexAction].steps.push({
      //                     ...newStep,
      //                     dlNo: newDlNo,
      //                     serialNumbers: [],
      //                   });
      //                 }
      //               }
      //             }
      //             return thisTransform;
      //           });
      //         } else {
      //           setTransform((thisTransform) => {
      //             if (thisTransform.running) {
      //               thisTransform.running = false;
      //               thisTransform.actions[indexAction].steps[
      //                 indexStep
      //               ].success = false;
      //               return clone(thisTransform);
      //             }
      //             return thisTransform;
      //           });
      //           if (response.statusCode === 401) {
      //             toastr.info(t("word.info"), t("error.reconnect"), {
      //               timeOut: 0,
      //             });
      //           } else {
      //             for (let message of getErrorApi(response.content)) {
      //               toastr.error(t("word.error"), t(message), {
      //                 timeOut: 0,
      //               });
      //             }
      //           }
      //         }
      //         return;
      //       }
      //     }
      //   }
      // }
      // setTransform((thisTransform) => {
      //   thisTransform.running = false;
      //   return clone(thisTransform);
      // });
      // endregion
    }, [arrivageId, token, transform]);

    useImperativeHandle(ref, () => ({
      focusMainSearch(e: any) {
        focusMainSearch(e);
      },
      setArrivage(newArrivage: ArrivageInterface) {
        if (newArrivage.id !== arrivage?.id) {
          return;
        }
        setArrivage((a) => {
          if (!a) {
            return a;
          }
          // @ts-ignore
          newArrivage = {
            ...addStateFDoclignes(newArrivage),
            updateTime: a.updateTime,
          };
          fDoclignesRef.current.updateArrivage(newArrivage);
          return newArrivage;
        });
      },
      arrivageDone(data: any) {
        if (data?.arrivageId.toString() !== arrivage?.id.toString()) {
          return;
        }
        toastr.info(
          t("word.info"),
          t("sentence.notification.arrivage_transformed").replace(
            "%arrivageId%",
            arrivageId ?? "0"
          ),
          {
            timeOut: 0,
          }
        );
        setTransform({
          actions: [],
          running: false,
        });
      },
    }));

    React.useEffect(() => {
      if (refreshPage || arrivage === undefined) {
        load();
      }
    }, [arrivageId, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const handleResize = () => {
        if (
          mainRef?.current?.offsetHeight &&
          headerRef?.current?.offsetHeight
        ) {
          setHeightComponent(
            mainRef?.current?.offsetHeight - headerRef?.current?.offsetHeight
          );
        }
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      let interval: any = null;
      if (headerRef?.current?.offsetHeight === undefined) {
        interval = setInterval(() => {
          if (headerRef?.current?.offsetHeight) {
            handleResize();
            clearInterval(interval);
          }
        }, 100);
      }
      return () => {
        window.removeEventListener("resize", handleResize);
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [headerRef?.current?.offsetHeight]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateActions();
    }, [transform]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (
        updatingArrivage.currentUpdateTime === undefined &&
        arrivage?.updateTime !== undefined &&
        arrivage.updateTime === arrivageUpdateTime &&
        arrivage.updateTime !== prevArrivageUpdateTime
      ) {
        setUpdatingArrivage((v) => {
          return {
            ...v,
            currentUpdateTime: arrivage.updateTime,
          };
        });
      }
    }, [updatingArrivage.currentUpdateTime, arrivage?.updateTime]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (
        updatingArrivage.currentUpdateTime === arrivage?.updateTime &&
        arrivageUpdateTime === arrivage?.updateTime
      ) {
        let thisUpdateTime = arrivage?.updateTime;
        const toSend: ArrivageInterface = clone(arrivage);
        if (toSend?.data?.fDocenteteLigneArrivages) {
          for (const f of toSend.data.fDocenteteLigneArrivages) {
            delete f.ref;
          }
        }
        const hasLastAddedArRef =
          arrivage.fDoclignes?.find((f) => f.arRef === lastAddedArRef) !==
          undefined;
        const body = {
          data: toSend.data,
          ignoredDocuments: toSend.ignoredDocuments,
        };
        requestApi({
          method: PATCH,
          path:
            ARRIVAGE_URL +
            "/" +
            toSend?.id +
            objectToQuery({
              cacheName: cacheName,
            }),
          allowError: true,
          token: token,
          body: body,
        }).then((response) => {
          if (response.statusCode === 200) {
            if (arrivageUpdateTime === thisUpdateTime) {
              if (
                lastAddedArRef &&
                !hasLastAddedArRef &&
                response.content.fDoclignes.find(
                  (f: ArrivageFDocligneInterface) => f.arRef === lastAddedArRef
                ) === undefined
              ) {
                lastAddedArRef = null;
                focusMainSearch();
                const thisArSuivistock =
                  response.content.data.fDocenteteLigneArrivages.find(
                    (f: FDocenteteLigneArrivageInterface) => {
                      return f.arRef === lastAddedArRef;
                    }
                  )?.arSuivistock;
                if (
                  thisArSuivistock &&
                  ![SUIVI_STOCK_TYPE_SERIE, SUIVI_STOCK_TYPE_LOT].includes(
                    thisArSuivistock
                  )
                ) {
                  buzzAudio.play();
                  toastr.warning(
                    t("word.error"),
                    t("error.arrivage.notFoundDocuments"),
                    {
                      timeOut: 0,
                    }
                  );
                }
              }
              // @ts-ignore
              setArrivage((a) => {
                if (!a) {
                  return a;
                }
                const newArrivage = {
                  ...addStateFDoclignes(response.content),
                  updateTime: a.updateTime,
                };
                fDoclignesRef.current.updateArrivage(newArrivage);
                return newArrivage;
              });
            }
            if (thisUpdateTime) {
              prevArrivageUpdateTime = thisUpdateTime;
            }
            setUpdatingArrivage((v) => {
              v.currentUpdateTime = undefined;
              return { ...v };
            });
            // nothing
          } else {
            if (!updatingArrivage.error) {
              setUpdatingArrivage((v) => {
                v.error = true;
                return { ...v };
              });
            }
            if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"), {
                timeOut: 0,
              });
            } else {
              for (let message of getErrorApi(response.content)) {
                toastr.error(t("word.error"), t(message), {
                  timeOut: 0,
                });
              }
            }
          }
        });
      }
    }, [updatingArrivage.currentUpdateTime]); // eslint-disable-line react-hooks/exhaustive-deps

    let colSpan: number = 5;

    return (
      <>
        {arrivage === undefined || refreshPage ? (
          <Skeleton
            variant="rectangular"
            height={150}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
        ) : arrivage === null ? (
          <NotFoundComponent />
        ) : (
          <Box>
            <Box ref={headerRef}>
              <Box sx={{ display: "flex" }}>
                <CircleIcon
                  sx={{
                    width: "30px",
                    color: updatingArrivage.error
                      ? red[500]
                      : updatingArrivage.currentUpdateTime
                      ? yellow[500]
                      : green[500],
                  }}
                />
                <Typography>{arrivage.name.replace(" ", " ")}</Typography>
                <Box sx={{ flex: 1 }}>
                  <SearchEntreeStockComponent
                    currentFocus={currentFocus}
                    onChange={onChange}
                    running={transform.running || updatingArrivage.error}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fastTransform}
                        onChange={handleFastTransform}
                        disabled={transform.running}
                      />
                    }
                    label={
                      <>
                        {t("word.fastTransform.label")}
                        <Tooltip title={t("word.fastTransform.explain")}>
                          <InfoIcon />
                        </Tooltip>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hideFDoclignes}
                        onChange={handleHideFDoclignes}
                        disabled={transform.running}
                      />
                    }
                    label={
                      <>
                        {t("word.hideNotFoundFDoclignes.label")}
                        <Tooltip
                          title={t("word.hideNotFoundFDoclignes.explain")}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </>
                    }
                  />
                </Box>
                <Box>
                  {arrivage.supplier && (
                    <Chip label={arrivage.supplier} color="primary" />
                  )}
                  {arrivage.mandatoryDocuments &&
                  arrivage.mandatoryDocuments.length > 0 ? (
                    <>
                      {arrivage.mandatoryDocuments.map(
                        (doPiece, indexDoPiece) => (
                          <Chip
                            key={indexDoPiece}
                            label={doPiece}
                            color="primary"
                          />
                        )
                      )}
                    </>
                  ) : (
                    <EntreeStockIgnoredDocumentsComponent
                      arrivage={arrivage}
                      updateIgnoredDocument={updateIgnoredDocument}
                      running={transform.running}
                    />
                  )}
                </Box>
              </Box>
              <FArticleNotSureArrivageComponent
                fArticles={fArticleNotSures}
                choose={addFDocenteteLigneArrivage}
              />
              <Divider sx={{ marginTop: 0.5 }} />
            </Box>
            <Box
              ref={contentRef}
              sx={{
                display: "flex",
                ...(heightComponent && {
                  maxHeight: heightComponent + "px",
                }),
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  paddingRight: 0.25,
                  paddingBottom: 4,
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  width: "430px",
                  overflowX: "hidden",
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {t("word.productsArrivage")}
                </Typography>
                {searchingFArticle && (
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
                <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                  {arrivage.data.fDocenteteLigneArrivages &&
                    arrivage.data.fDocenteteLigneArrivages.map(
                      (
                        fDocenteteLigneArrivage,
                        indexFDocenteteLigneArrivage
                      ) => (
                        <EntreeStockFArticleComponent
                          key={indexFDocenteteLigneArrivage}
                          ref={fDocenteteLigneArrivage.ref}
                          running={transform.running}
                          index={indexFDocenteteLigneArrivage}
                          fDocenteteLigneArrivage={fDocenteteLigneArrivage}
                          focusSerialNumberIndex={
                            arrivage.focusIndexAfterChangeQuantity
                          }
                          fDoclignes={
                            arrivage.fDoclignes?.filter(
                              (f) => f.arRef === fDocenteteLigneArrivage.arRef
                            ) ?? []
                          }
                          focusMainSearch={focusMainSearch}
                          setArrivage={setArrivage}
                        />
                      )
                    )}
                </Box>
              </Box>
              <Box
                sx={{
                  overflow: "auto",
                  flex: 1,
                }}
              >
                <EntreeStockDisplayFDocligneComponent
                  ref={fDoclignesRef}
                  initArrivage={arrivage}
                  focusMainSearch={focusMainSearch}
                  colSpan={colSpan}
                  transform={transform}
                  setTransform={setTransform}
                  updatingArrivage={updatingArrivage}
                  fastTransform={fastTransform}
                  updateIgnoredDocument={updateIgnoredDocument}
                  hideFDoclignes={hideFDoclignes}
                />
                {transform.running && (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "45px",
                      textAlign: "center",
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      sx={{
                        position: "fixed",
                        bottom: "20px",
                      }}
                      onClick={() => {
                        setTransform((thisTransform) => {
                          thisTransform.running = false;
                          thisTransform.actions = [];
                          return clone(thisTransform);
                        });
                      }}
                    >
                      {t("word.stop")}
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  })
);

export default EntreeStockArrivageComponent;
