import {
  CategoryInterface,
  FilterCategoryInterface,
} from "../interfaces/CategoryInterface";
import { DropResult } from "react-beautiful-dnd";

export interface Item {
  id: number;
  text: string;
  enabled?: boolean;
  opened?: boolean;
  reference?: boolean;
  active?: boolean;
  custom?: boolean;
  children: Item[];
  category?: CategoryInterface;
  showRestrictedValue?: boolean;
  type?: string;
}

export interface RequestUpdateCart {
  sort: number;
  id: number;
}

export interface UpdateCartMenuResult {
  request: RequestUpdateCart | undefined;
  cards: Item[];
}

export const getCardsCategory = (categories: CategoryInterface[]): Item[] => {
  categories.sort((a, b) => a.sort - b.sort);
  return (
    categories.map((category) => {
      categories = category.children ? [...category.children] : [];
      categories.sort((a, b) => a.sort - b.sort);
      return {
        category: category,
        id: category.id,
        text: category.name,
        enabled: category.enabled,
        children:
          category.children?.map((child) => {
            return {
              category: child,
              id: child.id,
              text: child.name,
              enabled: child.enabled,
              children: [],
            };
          }) ?? [],
      };
    }) ?? []
  );
};

export const getCardsFilterCategory = (
  filterCategories: FilterCategoryInterface[] | undefined
): Item[] => {
  return (
    filterCategories?.map((filterCategory) => {
      return {
        id: filterCategory.id,
        text: filterCategory.filter.name,
        opened: filterCategory.opened,
        reference: filterCategory.reference,
        active: filterCategory.active,
        showRestrictedValue: filterCategory.showRestrictedValue,
        custom: filterCategory.filter.custom,
        type: filterCategory.filter.type,
        children: [],
      };
    }) ?? []
  );
};

export const getPathFromDroppableId = (path: string): number[] => {
  return path
    .split(".")
    .filter((x) => x !== "")
    .map((x) => parseInt(x, 10));
};

export const updateCardResult = (
  cards: Item[],
  dropResult: DropResult
): UpdateCartMenuResult | undefined => {
  if (dropResult.destination?.index === undefined) {
    return undefined;
  }
  const newCardsSource = [...cards];
  let tempCards = newCardsSource;
  const path = getPathFromDroppableId(dropResult.source.droppableId);
  for (const index of path) {
    tempCards = tempCards[index].children;
  }
  const element = tempCards[dropResult.source.index];
  tempCards.splice(dropResult.source.index, 1);
  tempCards.splice(dropResult.destination.index, 0, element);
  return {
    request: {
      sort: dropResult.destination.index + 1,
      id: element.id,
    },
    cards: newCardsSource,
  };
};

export const swapCard = (data: any[], dropResult: DropResult): any[] => {
  if (dropResult.destination?.index === undefined) {
    return data;
  }
  const newData = [...data];
  const element = newData[dropResult.source.index];
  newData.splice(dropResult.source.index, 1);
  newData.splice(dropResult.destination.index, 0, element);
  return newData;
};
