import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { InputInterface } from "../../../../interfaces/InputInterface";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";

interface State {
  updateSearchParams?: Function;
}

interface FormState {
  from: InputInterface;
  to: InputInterface;
}

const ConsoleComptoirFilterDateComponent = React.memo(
  React.forwardRef(({ updateSearchParams }: State, ref) => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const getDefaultValues = React.useCallback((): FormState => {
      const searchParamsObject = searchParamToObject(searchParams);
      let after = searchParamsObject.hasOwnProperty("doDate[after]")
        ? new Date(
            searchParamsObject["doDate[after]"].split("/").reverse().join("-")
          )
        : null;
      let before = searchParamsObject.hasOwnProperty("doDate[before]")
        ? new Date(
            searchParamsObject["doDate[before]"].split("/").reverse().join("-")
          )
        : null;
      return {
        from: {
          value: after
            ? new Date(after.getTime() + after.getTimezoneOffset() * 60000)
            : after,
          error: "",
        },
        to: {
          value: before
            ? new Date(before.getTime() + before.getTimezoneOffset() * 60000)
            : before,
          error: "",
        },
      };
    }, [searchParams]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChangeDate = React.useCallback(
      (prop: keyof FormState) => (newValue: Date | null) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: newValue,
              error: "",
            },
          };
        });
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        if (values.from.value || values.to.value) {
          return {
            ...(values.from.value && {
              "doDate[after]": values.from.value.toLocaleDateString(
                i18n.language,
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  timeZone: "America/Cuiaba",
                }
              ),
            }),
            ...(values.to.value && {
              "doDate[before]": values.to.value.toLocaleDateString(
                i18n.language,
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  timeZone: "America/Cuiaba",
                }
              ),
            }),
          };
        }
        return {};
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (updateSearchParams) {
        updateSearchParams();
      }
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: "flex" }}>
          <Stack spacing={3} sx={{ width: "100%" }}>
            <DesktopDatePicker
              label={t("field.from")}
              // @ts-ignore
              format="dd/MM/yyyy"
              // @ts-ignore
              inputFormat="dd/MM/yyyy"
              value={values.from.value}
              onChange={handleChangeDate("from")}
              // @ts-ignore
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={!!values.from.error}
                  helperText={t(values.from.error ?? "")}
                />
              )}
            />
          </Stack>
          <Stack spacing={3} sx={{ width: "100%" }}>
            <DesktopDatePicker
              label={t("field.to")}
              // @ts-ignore
              format="dd/MM/yyyy"
              // @ts-ignore
              inputFormat="dd/MM/yyyy"
              value={values.to.value}
              onChange={handleChangeDate("to")}
              // @ts-ignore
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={!!values.to.error}
                  helperText={t(values.to.error ?? "")}
                />
              )}
            />
          </Stack>
        </Box>
      </LocalizationProvider>
    );
  })
);

export default ConsoleComptoirFilterDateComponent;
