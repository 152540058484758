import * as React from "react";
import { useRef } from "react";
import {
  FDocenteteInterface,
  FilterFDocenteteInterface,
  FilterFDocenteteOptionInterface,
} from "../../../interfaces/FDocenteteInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { FILTER_F_DOCENTETE_OPTIONS_URL } from "../../../utils/UrlsUtils";
import { FDOCENTETE_FILTER_TYPE_TEXT } from "../../../utils/DoceneteteUtils";
import { InputAdornment, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface State {
  fDocentete: FDocenteteInterface;
}

interface State2 {
  fDocentete: FDocenteteInterface;
  filterFDocentete: FilterFDocenteteInterface;
}

interface State3 {
  fDocentete: FDocenteteInterface;
  type: string;
  filterFDocenteteOption: FilterFDocenteteOptionInterface;
}

const FDocenteteCustomSingleOptionFilterComponent: React.FC<State3> =
  React.memo(({ type, fDocentete, filterFDocenteteOption }) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const inputRef: any = useRef();
    const [edit, setEdit] = React.useState(false);
    const [openCopied, setOpenCopied] = React.useState<boolean>(false);
    const getInputValue = React.useCallback(() => {
      if (type === FDOCENTETE_FILTER_TYPE_TEXT) {
        return (
          fDocentete.filterFDocenteteValues?.find(
            (f) => f.filterFDocenteteOption.id === filterFDocenteteOption.id
          )?.value ?? ""
        );
      }
      return (
        fDocentete.filterFDocenteteValues?.find(
          (f) => f.filterFDocenteteOption.id === filterFDocenteteOption.id
        )?.value === "1"
      );
    }, [fDocentete.filterFDocenteteValues, filterFDocenteteOption.id, type]);
    const [inputValue, setInputValue] = React.useState(getInputValue());
    const [disabled, setDisabled] = React.useState(false);
    const copy = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(inputValue.toString());
        setOpenCopied(true);
        setTimeout(() => {
          setOpenCopied(false);
        }, 500);
      },
      [inputValue]
    );
    const handleChange = React.useCallback(
      (id: number, value: string | boolean | null) => async (event: any) => {
        setDisabled(true);
        let newValue: string | boolean | null;
        if (type === FDOCENTETE_FILTER_TYPE_TEXT) {
          newValue = inputValue;
        } else {
          newValue = event.target.checked;
          setInputValue(!!newValue);
        }
        const response = await requestApi({
          method: PATCH,
          path: FILTER_F_DOCENTETE_OPTIONS_URL + "/" + id,
          allowError: true,
          body: {
            filterFDocenteteValue: {
              fDocenteteId: fDocentete.doPiece,
              value: value,
            },
          },
          token: token,
        });
        if (response.statusCode === 200) {
          toastr.success(t("word.success"), "");
          setEdit(false);
        } else {
          setInputValue(!newValue);
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        }
        setDisabled(false);
      },
      [fDocentete.doPiece, inputValue, t, token, type]
    );

    const handleEdit = React.useCallback(() => {
      setEdit((x) => {
        if (!x) {
          setTimeout(() => {
            inputRef.current.focus();
            inputRef.current.select();
          });
        }
        return !x;
      });
    }, []);

    return (
      <>
        {type === FDOCENTETE_FILTER_TYPE_TEXT ? (
          <TextField
            fullWidth={true}
            disabled={!edit}
            inputRef={inputRef}
            autoComplete="off"
            sx={{ width: "100%", maxWidth: "400px" }}
            value={inputValue}
            onChange={(event: any) => {
              setInputValue(event.target.value);
            }}
            label={filterFDocenteteOption.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!edit ? (
                    <>
                      <Tooltip title={t("word.edit.word")}>
                        <IconButton onClick={handleEdit}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={t("word.cancel")}>
                        <IconButton onClick={handleEdit}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")}>
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={disabled}
                          onClick={handleChange(
                            filterFDocenteteOption.id,
                            inputValue ?? null
                          )}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip
                    title={t("word.copied")}
                    open={openCopied}
                    sx={{ ...(edit && { display: "none" }) }}
                  >
                    <IconButton onClick={copy}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!inputValue}
                  disabled={disabled}
                  onChange={handleChange(
                    filterFDocenteteOption.id,
                    inputValue ? null : "1"
                  )}
                />
              }
              label={filterFDocenteteOption.name}
            />
          </FormGroup>
        )}
      </>
    );
  });

const FDocenteteCustomSingleFilterComponent: React.FC<State2> = React.memo(
  ({ fDocentete, filterFDocentete }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>{filterFDocentete.name}</Typography>
        <Box sx={{ marginLeft: 1 }}>
          {filterFDocentete.filterFDocenteteOptions.map(
            (filterFDocenteteOption, indexFilterFDocenteteOption) => (
              <FDocenteteCustomSingleOptionFilterComponent
                type={filterFDocentete.type}
                fDocentete={fDocentete}
                filterFDocenteteOption={filterFDocenteteOption}
                key={indexFilterFDocenteteOption}
              />
            )
          )}
        </Box>
      </Box>
    );
  }
);

const FDocenteteCustomFilterComponent: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const filterFDocentetes = useAppSelector(
      (state: RootState) => state.globalState.filterFDocentetes
    );

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}>
        {filterFDocentetes?.map((filterFDocentete, indexFilterFDocentete) => {
          return (
            <Box
              key={indexFilterFDocentete}
              sx={{
                display: "flex",
                alignItems: "center",
                ...(indexFilterFDocentete < filterFDocentetes.length - 1 && {
                  borderRight: "1px solid",
                  marginRight: 1,
                }),
              }}
            >
              {(filterFDocentete.doTypes === null ||
                filterFDocentete.doTypes.includes(fDocentete.doType)) && (
                <>
                  <FDocenteteCustomSingleFilterComponent
                    fDocentete={fDocentete}
                    filterFDocentete={filterFDocentete}
                  />
                </>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }
);

export default FDocenteteCustomFilterComponent;
