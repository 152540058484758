import React, { useRef } from "react";
import { set } from "../../../app/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Skeleton,
} from "@mui/material";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GET } from "../../../utils/MethodUtils";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import FDocenteteComponent from "./FDocenteteComponent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import FilterFDocentetesComponent from "../fdocentete/FilterFDocentetesComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import LoginComponent from "../user/LoginComponent";
import PostOrderComponent from "./PostOrderComponent";
import CustomPaginationComponent from "../CustomPaginationComponent";
import ContentComponent from "../../content/ContentComponent";
import thanksGif from "../../../assets/thanks.gif";
import Button from "@mui/material/Button";
import ConfigureViewOrdersComponent from "./ConfigureViewOrdersComponent";
import { STORAGE_INFINIT_SCROLL_ORDERS } from "../../../utils/StorageUtils";

const clone = require("clone");

interface State {
  newOrder?: boolean | null;
  newDevis?: boolean | null;
}

const defaultItemsPerPage = 10;

const OrdersComponent: React.FC<State> = React.memo(
  ({ newOrder, newDevis }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const [fDocentetes, setFDocentetes] = React.useState<
      FDocenteteInterface[] | undefined
    >(undefined);
    const postOrders = useAppSelector(
      (state: RootState) => state.globalState.postOrders
    );
    const getSort = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key, value] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          return key.replace("order[", "").replace("]", "") + "|" + value;
        }
      }
      return "cbmarq|desc";
    }, [searchParams]);
    const [sort, setSort] = React.useState<string>(getSort());
    const [totalItems, setTotalItems] = React.useState<number | undefined>(
      undefined
    );
    const [nbPage, setNbPage] = React.useState<number | undefined>(undefined);
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState<boolean>(
      !!newOrder || !!newDevis
    );
    const { t } = useTranslation();
    const [alertMessage] = React.useState<string>(() => {
      if (newOrder) {
        return t("sentence.orderCreated");
      }
      if (newDevis) {
        return t("sentence.devisCreated");
      }
      return "";
    });
    const [nbSelected, setNbSelected] = React.useState<number>(0);
    const [pageToLoad, setPageToLoad] = React.useState<number>(1);
    const [searching, setSearching] = React.useState<boolean>(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useAppDispatch();
    const [init, setInit] = React.useState(false);
    const filterRef: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const infinitScrollOrders = useAppSelector(
      (state: RootState) => state.globalState.infinitScrollOrders
    );
    const configureRef: any = useRef();

    const handleChangePage = React.useCallback(
      (event: React.ChangeEvent<unknown>, value: number) => {
        const searchParamsObject = searchParamToObject(searchParams);
        searchParamsObject.page = value;
        setSearchParams(searchParamsObject, {
          replace: true,
        });
      },
      [searchParams, setSearchParams]
    );

    const handleOpen = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
      setOpen(false);
    }, []);

    const handleChange = React.useCallback((event: SelectChangeEvent) => {
      setSort(event.target.value as string);
    }, []);

    const paramsHasChanged = React.useCallback(
      (currentSearchParams: any, newSearchParams: any) => {
        const cloneCurrentSearchParams: any = clone(currentSearchParams);
        const cloneNewSearchParams: any = clone(newSearchParams);
        delete cloneCurrentSearchParams.page;
        delete cloneNewSearchParams.page;
        return (
          new URLSearchParams(cloneCurrentSearchParams).toString() !==
          new URLSearchParams(cloneNewSearchParams).toString()
        );
      },
      []
    );

    const apply = React.useCallback(() => {
      if (!init) {
        return;
      }
      const current = searchParamToObject(searchParams);
      const newSearchParams = {
        ...filterRef.current?.getValue(),
        page: current.page ?? 1,
        itemsPerPage: current.itemsPerPage ?? 10,
        doTiers: user?.userIdentifier,
      };
      for (const key in newSearchParams) {
        if (key.startsWith("order")) {
          delete newSearchParams[key];
        }
      }
      if (sort !== "") {
        const sortArray = sort.split("|");
        newSearchParams["order[" + sortArray[0] + "]"] = sortArray[1];
      }
      if (paramsHasChanged(current, newSearchParams)) {
        newSearchParams.page = 1;
      }
      setSearchParams(newSearchParams, {
        replace: true,
      });
      setOpen(false);
    }, [
      init,
      paramsHasChanged,
      searchParams,
      setSearchParams,
      sort,
      user?.userIdentifier,
    ]);

    const getFDocenetetes = React.useCallback(async () => {
      setSearching(true);
      const searchParamsObject = searchParamToObject(
        new URLSearchParams(window.location.search)
      );
      if (infinitScrollOrders) {
        searchParamsObject.page = pageToLoad;
      }
      const path = FDOCENTETES_URL + objectToQuery(searchParamsObject);
      const response = await requestApi({
        method: GET,
        path: path + "&getFilterFDocenteteValues=1",
        allowError: false,
        paginate: true,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        const newSearchParamsObject = searchParamToObject(
          new URLSearchParams(window.location.search)
        );
        if (infinitScrollOrders) {
          newSearchParamsObject.page = pageToLoad;
        }
        let newPath = FDOCENTETES_URL + objectToQuery(newSearchParamsObject);
        if (path !== newPath) {
          setSearching(false);
          return;
        }
        setTotalItems(response.content.totalItems);
        setNbPage(Math.ceil(response.content.totalItems / defaultItemsPerPage));
        if (infinitScrollOrders) {
          setFDocentetes((x) => {
            if (!x) {
              x = [];
            }
            const r = [...x, ...response.content.fDocentetes];
            return r.filter(
              (a, i) => r.findIndex((s) => a.doPiece === s.doPiece) === i
            );
          });
        } else {
          setFDocentetes(response.content.fDocentetes);
        }
        dispatch(
          set({
            accountingSituation: response.content.accountingSituation,
            postOrders: response.content.postOrders,
          })
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setSearching(false);
    }, [dispatch, infinitScrollOrders, pageToLoad, t, token]);

    const load = React.useCallback(
      async (force: boolean = false) => {
        if (user === undefined) {
          setInit(true);
          dispatch(set({ refreshPage: false }));
          return;
        }
        if (fDocentetes !== undefined && !force) {
          dispatch(set({ refreshPage: false }));
          setInit(true);
          return;
        }
        const searchParamsObject = searchParamToObject(searchParams);
        const oldSearchParamsObject = { ...searchParamsObject };
        if (!searchParamsObject.hasOwnProperty("page")) {
          searchParamsObject.page = 1;
        }
        if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
          searchParamsObject.itemsPerPage = defaultItemsPerPage;
        }
        if (
          !searchParamsObject.hasOwnProperty("doTiers") ||
          searchParamsObject.doTiers !== user.userIdentifier
        ) {
          searchParamsObject.doTiers = user.userIdentifier;
        }
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[cbmarq]"] = "desc";
        }
        if (
          JSON.stringify(searchParamsObject) !==
          JSON.stringify(oldSearchParamsObject)
        ) {
          setInit(true);
          setSearchParams(searchParamsObject, {
            replace: true,
          });
          return;
        }
        setFDocentetes(undefined);
        delete searchParamsObject.page;
        delete searchParamsObject.itemsPerPage;
        delete searchParamsObject.doTiers;
        setNbSelected(Object.keys(searchParamsObject).length - 1); // -1 because of order parameter
        await getFDocenetetes();
        dispatch(set({ refreshPage: false }));
        setInit(true);
      },
      [
        dispatch,
        fDocentetes,
        getFDocenetetes,
        searchParams,
        setSearchParams,
        user,
      ]
    );

    const onPressKey = React.useCallback(
      (event: KeyboardEvent) => {
        // @ts-ignore
        const target = event.target;
        if (
          refreshPage ||
          !fDocentetes ||
          (target instanceof HTMLInputElement &&
            // @ts-ignore
            event.target.type.toLowerCase() === "text" &&
            // @ts-ignore
            event.target.tagName.toLowerCase() === "input")
        ) {
          return;
        }
        const searchParamsObject = searchParamToObject(searchParams);
        const page = Number(searchParamsObject.page) ?? 1;
        let value = null;
        if (nbPage !== undefined) {
          if (event.code === "ArrowRight" && page < nbPage) {
            value = page + 1;
          } else if (event.code === "ArrowLeft" && page > 1) {
            value = page - 1;
          }
        }
        if (value) {
          searchParamsObject.page = value;
          setSearchParams(searchParamsObject, {
            replace: true,
          });
        }
      },
      [fDocentetes, nbPage, refreshPage, searchParams, setSearchParams]
    );

    const changeInfinitScroll = React.useCallback(
      (value: boolean) => {
        dispatch(set({ infinitScrollOrders: value }));
        localStorage.setItem(STORAGE_INFINIT_SCROLL_ORDERS, value.toString());
      },
      [dispatch]
    );

    const onScrollOrders = React.useCallback(
      (e: any) => {
        if (!infinitScrollOrders) {
          return;
        }
        const position = e.target
          .querySelector("#orders-container")
          ?.getBoundingClientRect();
        if (!position) {
          return;
        }
        const isBottom =
          e.target.offsetHeight + e.target.scrollTop >=
          e.target.scrollHeight - 2000;
        if (isBottom) {
          const searchParamsObject = searchParamToObject(searchParams);
          if (
            !fDocentetes ||
            !totalItems ||
            fDocentetes.length >= totalItems ||
            !searchParamsObject.itemsPerPage
          ) {
            return;
          }
          const newPage =
            Math.ceil(fDocentetes.length / searchParamsObject.itemsPerPage) + 1;
          if (newPage !== pageToLoad) {
            setPageToLoad(newPage);
          }
        }
      },
      [fDocentetes, infinitScrollOrders, pageToLoad, searchParams, totalItems]
    );

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      window.addEventListener("keydown", onPressKey);
      return () => {
        window.removeEventListener("keydown", onPressKey);
      };
    }, [fDocentetes, nbPage, refreshPage, searchParams, setSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      apply();
    }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        load(true);
      }
    }, [init, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!user) {
        setSearchParams(
          {},
          {
            replace: true,
          }
        );
      }
      if (init && !refreshPage) {
        if (infinitScrollOrders && pageToLoad !== 1) {
          setFDocentetes(undefined);
          setPageToLoad(1);
          return;
        }
        load(true);
      }
    }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && !refreshPage) {
        getFDocenetetes();
      }
    }, [pageToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && !refreshPage) {
        dispatch(set({ refreshPage: true }));
      }
    }, [infinitScrollOrders]); // eslint-disable-line react-hooks/exhaustive-deps

    const searchParamsObject = searchParamToObject(searchParams);
    return (
      <>
        <LoginComponent redirect={null}>
          <>
            {!isSmall && (
              <Box
                sx={{
                  paddingLeft: 4,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingRight: 1,
                  maxWidth: "25%",
                  minWidth: "15%",
                  overflow: "auto",
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: "auto",
                }}
              >
                <FilterFDocentetesComponent apply={apply} ref={filterRef} />
              </Box>
            )}
            <Container
              maxWidth={false}
              sx={{
                paddingTop: 2,
                paddingBottom: isSmall ? 6 : 2,
                overflow: "auto",
              }}
              onScroll={onScrollOrders}
            >
              <Collapse in={openAlert}>
                <Alert
                  severity="success"
                  variant="outlined"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ marginY: 2 }}
                >
                  {alertMessage}
                  {user?.userIdentifier === "I46977" && (
                    <img src={thanksGif} alt="thanks" />
                  )}
                </Alert>
              </Collapse>
              <Card variant="outlined" sx={{ marginBottom: 1 }}>
                <CardContent sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {totalItems !== undefined && fDocentetes !== undefined ? (
                      totalItems + " " + t("word.results")
                    ) : (
                      <Skeleton variant="text" sx={{ width: "100%" }} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="sort-products">
                        {t("field.sort")}
                      </InputLabel>
                      <Select
                        disabled={fDocentetes === undefined}
                        labelId="sort-fdocentete"
                        value={sort}
                        label={t("field.sort")}
                        onChange={handleChange}
                      >
                        <MenuItem value={"cbmarq|asc"}>
                          {t("sort.date.asc")}
                        </MenuItem>
                        <MenuItem value={"cbmarq|desc"}>
                          {t("sort.date.desc")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      paddingLeft: 1,
                      textAlign: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => configureRef.current.toggleShow()}
                    >
                      {t("word.configuration.display")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
              <ConfigureViewOrdersComponent
                ref={configureRef}
                changeInfinitScroll={changeInfinitScroll}
              />
              <ContentComponent contentName="orders" saveLocalStorage={true} />
              {postOrders !== undefined && (
                <>
                  {postOrders.map((postOrder) => (
                    <PostOrderComponent
                      postOrder={postOrder}
                      key={postOrder.id}
                      load={load}
                    />
                  ))}
                </>
              )}
              {fDocentetes === undefined ? (
                Array.from(Array(defaultItemsPerPage)).map(
                  (skeleton, indexSkeleton) => (
                    <Skeleton
                      key={indexSkeleton}
                      variant="rectangular"
                      height={150}
                      sx={{ marginY: 2 }}
                    />
                  )
                )
              ) : (
                <>
                  {!infinitScrollOrders && (
                    <CustomPaginationComponent
                      nbPage={nbPage}
                      page={Number(searchParamsObject.page) ?? 1}
                      handleChangePage={handleChangePage}
                    />
                  )}
                  <Box id="orders-container">
                    {fDocentetes.map((fDocentete) => (
                      <FDocenteteComponent
                        fDocentete={fDocentete}
                        key={fDocentete.doPiece}
                      />
                    ))}
                    {searching && infinitScrollOrders && (
                      <Box sx={{ textAlign: "center" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                  {!infinitScrollOrders && (
                    <CustomPaginationComponent
                      nbPage={nbPage}
                      page={Number(searchParamsObject.page) ?? 1}
                      handleChangePage={handleChangePage}
                    />
                  )}
                </>
              )}
              {isSmall && (
                <Box
                  sx={{
                    flex: 0,
                    textAlign: "center",
                    position: "absolute",
                    bottom: 5,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <LoadingButton
                    sx={{ wordBreak: "break-word" }}
                    variant="contained"
                    loading={false}
                    onClick={handleOpen}
                  >
                    {t("word.filters")}
                    {nbSelected > 0 && (
                      <Typography
                        component="span"
                        className="badge"
                        sx={{ marginLeft: 1 }}
                      >
                        {nbSelected}
                      </Typography>
                    )}
                  </LoadingButton>
                </Box>
              )}
            </Container>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="dialog-filter"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box sx={{ flex: 1, overflow: "auto" }}>
                  <DialogTitle id="dialog-filter">
                    {t("word.filter.label")}
                  </DialogTitle>
                  <DialogContent>
                    {isSmall && (
                      <FilterFDocentetesComponent
                        apply={apply}
                        ref={filterRef}
                      />
                    )}
                  </DialogContent>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: 0,
                    marginY: 1,
                  }}
                >
                  <Box>
                    <LoadingButton
                      sx={{ wordBreak: "break-word", marginLeft: 1 }}
                      loading={false}
                      onClick={handleClose}
                    >
                      {t("word.cancel")}
                    </LoadingButton>
                  </Box>
                  <Box>
                    <LoadingButton
                      sx={{ wordBreak: "break-word", marginRight: 1 }}
                      variant="contained"
                      loading={false}
                      onClick={apply}
                    >
                      {t("word.apply")}
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </>
        </LoginComponent>
      </>
    );
  }
);

export default OrdersComponent;
