import React from "react";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import { Container } from "@mui/material";
import { getTitle } from "../../helpers/SearchParamHelper";
import EdiComponent from "../../components/common/edi/EdiComponent";

const EdiScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("edi");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container maxWidth="xl">
        <EdiComponent />
      </Container>
    </Layout>
  );
});

export default EdiScreen;
