import React from "react";
import { useParams } from "react-router-dom";
import { InvoiceInterface } from "../../interfaces/EdiInterface";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";
import { set } from "../../app/globalSlice";
import { requestApi } from "../../helpers/RequestApi";
import { GET } from "../../utils/MethodUtils";
import { INVOICE_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { getTitle } from "../../helpers/SearchParamHelper";
import Layout from "../../components/common/Layout";
import InvoiceComponent from "../../components/common/edi/InvoiceComponent";
import LoginComponent from "../../components/common/user/LoginComponent";
import Box from "@mui/material/Box";

const InvoiceScreen: React.FC = React.memo(() => {
  const params = useParams();
  const [invoice, setInvoice] = React.useState<InvoiceInterface | undefined>(
    undefined
  );
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const { t } = useTranslation();

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!force && invoice !== undefined) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      if (force) {
        setInvoice(undefined);
      }
      const response = await requestApi({
        method: GET,
        path: INVOICE_URL + "/" + params.id,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setInvoice(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [dispatch, invoice, params.id, t, token]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let title = getTitle("invoice");
    document.title = title + " Informatique Guadeloupe NOVE";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      setInvoice(undefined);
      load(true);
    }
  }, [JSON.stringify(params)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Box sx={{ marginY: 2 }}>
        <LoginComponent redirect={null}>
          <InvoiceComponent initInvoice={invoice} />
        </LoginComponent>
      </Box>
    </Layout>
  );
});

export default InvoiceScreen;
