import React, { forwardRef, useImperativeHandle } from "react";
import { FilterCategoryInterface } from "../../../interfaces/CategoryInterface";
import { useSearchParams } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useMediaQuery, useTheme } from "@mui/material";

interface State {
  filterCategory: FilterCategoryInterface;
  apply: Function;
  applyHidden: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
  hidden: boolean;
}

const FilterValueNumericComponent = React.memo(
  forwardRef(
    (
      {
        filterCategory,
        apply,
        applyHidden,
        setNbSelected,
        index,
        filterInited,
        hidden,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();
      const getMin = React.useCallback(() => {
        return filterCategory.showRestrictedValue &&
          filterCategory.restrictedValues?.length
          ? filterCategory.restrictedValues[0]
          : filterCategory.rangeTo ?? 0;
      }, [
        filterCategory.rangeTo,
        filterCategory.restrictedValues,
        filterCategory.showRestrictedValue,
      ]);
      const [min, setMin] = React.useState<number>(getMin());
      const getMax = React.useCallback(() => {
        return filterCategory.showRestrictedValue &&
          filterCategory.restrictedValues?.length
          ? filterCategory.restrictedValues[
              filterCategory.restrictedValues?.length - 1
            ]
          : filterCategory.rangeTo ?? 0;
      }, [
        filterCategory.rangeTo,
        filterCategory.restrictedValues,
        filterCategory.showRestrictedValue,
      ]);
      const [max, setMax] = React.useState<number>(getMax());
      const getValue = React.useCallback(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        const result = [min, max];
        if (filterCategory.showRestrictedValue) {
          result[0] = 0;
          if (filterCategory.restrictedValues) {
            result[1] = filterCategory.restrictedValues.length - 1;
          }
        }
        if (hidden) {
          return result;
        }
        if (searchParamsObject.hasOwnProperty("filterValueNumerics[]")) {
          for (const filterValueNumeric of searchParamsObject[
            "filterValueNumerics[]"
          ]) {
            const thisInitValue = JSON.parse(filterValueNumeric);
            if (thisInitValue.filterId === filterCategory.filter.id) {
              if (thisInitValue.min !== null) {
                if (filterCategory.showRestrictedValue) {
                  result[0] =
                    filterCategory.restrictedValues?.findIndex(
                      (x) => x === thisInitValue.min
                    ) ?? 0;
                } else {
                  result[0] = thisInitValue.min;
                }
              }
              if (thisInitValue.max !== null) {
                if (
                  filterCategory.showRestrictedValue &&
                  filterCategory.restrictedValues
                ) {
                  result[1] =
                    filterCategory.restrictedValues?.findIndex(
                      (x) => x === thisInitValue.max
                    ) ?? filterCategory.restrictedValues.length - 1;
                } else {
                  result[1] = thisInitValue.max;
                }
              }
              return result;
            }
          }
        }
        return result;
      }, [
        filterCategory.filter.id,
        filterCategory.restrictedValues,
        filterCategory.showRestrictedValue,
        hidden,
        max,
        min,
        searchParams,
      ]);
      const [value, setValue] = React.useState<number[]>(getValue());
      const [tempValue, setTempValue] = React.useState<number[]>(value);

      const handleChange = React.useCallback(
        (event: Event, newValue: number | number[]) => {
          setTempValue(newValue as number[]);
        },
        []
      );

      function calculateValue(value: number) {
        if (
          filterCategory.showRestrictedValue &&
          filterCategory.restrictedValues
        ) {
          return filterCategory.restrictedValues[value];
        }
        return value;
      }

      const handleChangeCommitted = React.useCallback(() => {
        setValue(tempValue);
      }, [tempValue]);

      const getResult = React.useCallback(() => {
        const result: any = {};
        let value0 = value[0];
        let value1 = value[1];
        if (filterCategory.showRestrictedValue) {
          value0 = filterCategory.restrictedValues![value[0]];
          value1 = filterCategory.restrictedValues![value[1]];
        }
        if (!hidden && (value0 !== min || value1 !== max)) {
          result["filterValueNumerics[]"] = JSON.stringify({
            filterId: filterCategory.filter.id,
            min: value0 !== min ? value0 : null,
            max: value1 !== max ? value1 : null,
          });
        }
        return result;
      }, [
        filterCategory.filter.id,
        filterCategory.restrictedValues,
        filterCategory.showRestrictedValue,
        hidden,
        max,
        min,
        value,
      ]);

      const getNbSelected = React.useCallback(() => {
        return JSON.stringify(getResult()) === "{}" ? 0 : 1;
      }, [getResult]);

      const onCheckedChange = React.useCallback(() => {
        setNbSelected(getNbSelected());
        setTempValue(value);
        if (!isSmall) {
          apply();
        } else {
          applyHidden();
        }
      }, [apply, applyHidden, getNbSelected, isSmall, setNbSelected, value]);

      React.useEffect(() => {
        setNbSelected(getNbSelected());
        filterInited(index);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValue(getValue());
      }, [hidden]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        onCheckedChange();
      }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setMin(getMin());
        setMax(getMax());
      }, [filterCategory]); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          return getResult();
        },
      }));

      return (
        <Box sx={{ paddingX: 3, paddingTop: 4 }}>
          <Slider
            value={tempValue}
            min={filterCategory.showRestrictedValue ? 0 : min}
            max={
              filterCategory.showRestrictedValue
                ? filterCategory.restrictedValues?.length
                  ? filterCategory.restrictedValues?.length - 1
                  : 0
                : max
            }
            onChange={handleChange}
            scale={calculateValue}
            onChangeCommitted={handleChangeCommitted}
            step={filterCategory.showRestrictedValue ? null : undefined}
            valueLabelDisplay="on"
            marks={
              filterCategory.showRestrictedValue
                ? filterCategory.restrictedValues?.map((v, i) => {
                    return {
                      value: i,
                      label:
                        i === 0 ||
                        i === (filterCategory.restrictedValues?.length ?? 1) - 1
                          ? v.toString()
                          : "",
                    };
                  })
                : undefined
            }
          />
        </Box>
      );
    }
  )
);

export default FilterValueNumericComponent;
